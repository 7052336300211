import type {
  ImagemapFlexModule,
  ImagemapModule,
} from 'shared/components/Editor/LineMessageEditor/types';

import { isImagemapContentBox } from 'shared/components/Editor/LineMessageEditor/utils/helper';
import { LineEditorMessageModuleTypeEnum } from 'shared/models/editor/lineEditor';

export const parseFlexToImagemap = (imagemapFlex: ImagemapFlexModule): ImagemapModule => {
  const { module_id, data, actions, format, ...rest } = imagemapFlex;
  const [imagemapContentImage] = data.contents?.body?.contents ?? [];

  const ratio = imagemapContentImage
    ? parseFloat(imagemapContentImage.aspectRatio?.split(':')[0] ?? '1') /
      parseFloat(imagemapContentImage.aspectRatio?.split(':')[1] ?? '1')
    : 1;

  const imageContentBoxes = data.contents?.body?.contents.filter(isImagemapContentBox) ?? [];

  return {
    module_id: LineEditorMessageModuleTypeEnum.Imagemap,
    data: {
      base_url: imagemapContentImage?.url ?? '',
      notification_text: data.notification_text,
      animated: imagemapContentImage?.animated ?? false,
      base_size: {
        width: 1040,
        height: 1040 / ratio,
      },
      actions: imageContentBoxes.map((content) => {
        const updatedTagId = format.tagId + 1;
        const area = {
          x: (parseFloat(content.offsetStart?.replace('%', '') ?? '0') / 100) * 1040,
          y: (parseFloat(content.offsetTop?.replace('%', '') ?? '0') / 100) * (1040 / ratio),
          width: (parseFloat(content.width?.replace('%', '') ?? '0') / 100) * 1040,
          height: (parseFloat(content.height?.replace('%', '') ?? '0') / 100) * (1040 / ratio),
        };
        if (content.action.type === 'message') {
          return {
            type: 'message',
            text: content.action.text,
            key: `message${updatedTagId}`,
            area,
          };
        } else {
          return {
            type: 'uri',
            linkUri: content.action.uri,
            area,
          };
        }
      }),
    },
    actions: [],
    format,
    ...rest,
  };
};
