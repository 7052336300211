import { memo, useMemo } from 'react';

import type { EditorDataType } from 'components/LineMessageEditor/models/templateDataAndTypes/types';
import type { LineMessageEditorPreviewProps } from 'shared/components/Editor/LineMessageEditor/components/LineMessageEditorPreview';
import type { LineMessageEditorInput } from 'shared/components/Editor/LineMessageEditor/types';

import { LineMessageEditorPreview } from 'shared/components/Editor/LineMessageEditor/components/LineMessageEditorPreview';
import { transformServerMessageToUiFormat } from 'shared/components/Editor/LineMessageEditor/hooks/useLineMessageEditorForm';

interface LineEditorLiveViewProps {
  initialData?: Array<EditorDataType>;
  headingMessage?: React.ReactNode;
  switcher?: LineMessageEditorPreviewProps['switcher'];
}

export const LineEditorLiveView = memo(function LineEditorLiveView({
  initialData,
  headingMessage,
  switcher,
}: LineEditorLiveViewProps) {
  const messages = useMemo(
    () =>
      transformServerMessageToUiFormat(
        initialData ? (initialData as LineMessageEditorInput['messages']) : [],
      ),
    [initialData],
  );

  return (
    <LineMessageEditorPreview
      switcher={switcher}
      fullWidthTab={true}
      messages={messages}
      headingMessage={headingMessage}
    />
  );
});
