import { immerable } from 'immer';

import {
  PARAM_PLACEHOLDER,
  PARAM_RICH_TEXT_FIELD_NAME_INDEX,
} from 'components/LineMessageEditor/constants';

import type { TextFormatData } from './richtext';
import type {
  AbstractEditorData,
  Action,
  CustomParameter,
  CustomParameterData,
  ImageSourceType,
  LinkParameter,
  LinkParameterData,
  ParamRichTextParameterData,
  ShareButtonParameter,
  ShareButtonParameterData,
  TextDataParameterCustom,
  TextDataParameterName,
  Utm,
} from './types';
import type {
  Action as FlexButtonAction,
  FlexBox,
  FlexButton,
  FlexImage,
  FlexText,
  MessageAction,
  URIAction,
} from '@line/bot-sdk';
import type { LineFieldType } from 'components/LineMessageEditor/models/templateDataAndTypes/LineFieldType';
import type { SetOptional } from 'type-fest';

import { generateRichEditorId } from 'components/LineMessageEditor/utils/richEditorId';
import { generateMappingKey, isMappingKey } from 'components/LineMessageEditor/utils/transformKey';
import { i18n } from 'modules/G11n/i18n';
import { theme } from 'theme';

import {
  createCustomParameter,
  createLinkParameter,
  createShareButtonParameter,
  isCustomParameter,
  isLinkParameter,
  isShareButtonParameter,
} from './types';

const { CardTitle: cardTitleIndex, CardDescription: cardDescriptionIndex } =
  PARAM_RICH_TEXT_FIELD_NAME_INDEX;

type ParamRichTextFormatData = Omit<TextFormatData, 'tagId'>;
export interface ParamRichTextFormat {
  format: Pick<TextFormatData, 'tagId'>;
}

interface MaacFlexBubbleFooter extends Omit<FlexBox, 'contents'> {
  contents: Array<ButtonWithKey>;
}

interface FlexImageWithStringAspectMode extends Omit<FlexImage, 'aspectRatio'> {
  aspectRatio: FlexImage['aspectRatio'] | string;
  key?: string;
}

export type CardRichEditorIds = [number, number];

export interface CardRichEditorIdsInjection {
  richEditorIds: CardRichEditorIds;
}

export interface CardDataBodyType extends FlexBox {
  contents: [FlexText, FlexText];
}

export interface CardDataType {
  format: [ParamRichTextFormatData, ParamRichTextFormatData];
  contents: {
    type: 'bubble';
    direction: 'ltr';
    hero?: FlexImageWithStringAspectMode;
    body: CardDataBodyType;
    footer: MaacFlexBubbleFooter;
  };
}

export type ButtonWithKey = Pick<FlexButton, 'type'> & {
  action: Extract<FlexButtonAction, { type: 'message' | 'uri' }>;
} & {
  key: string;
};

export function isFlexButtonActionMessageRule(
  node: FlexButtonAction,
): node is MessageAction & { label: string } {
  return node.type === 'message';
}

export function isFlexButtonActionUriRule(
  node: FlexButtonAction,
): node is URIAction & { label: string } {
  return node.type === 'uri';
}
export const CARD_BUTTON_TYPE = {
  message: 'message',
  uri: 'uri',
  customUri: 'customUri',
  shareButton: 'shareButton',
} as const;

export type CardButtonType = (typeof CARD_BUTTON_TYPE)[keyof typeof CARD_BUTTON_TYPE];

export type TagActionData = {
  type: 'standard';
  function: 'tag';
  tag_list: Array<string>;
};

export interface TagAction extends Action<TagActionData> {
  key: string;
}

export type ParamRichTextParameter = TextDataParameterName | TextDataParameterCustom;

export const createParamRichTextFormat = ({
  richEditorId,
  rowData,
  isFocus,
}: SetOptional<ParamRichTextFormatData, 'rowData' | 'isFocus'>): ParamRichTextFormatData => ({
  richEditorId,
  rowData: rowData ?? '',
  isFocus: isFocus ?? false,
});

const createTagAction = (
  props: Omit<Partial<TagAction>, 'key'> & { key: string },
  tagList: Array<string> = [],
): TagAction => ({
  trigger_type: 'message',
  trigger_code: '',
  data: {
    function: 'tag',
    tag_list: tagList,
    type: 'standard',
  },
  ...props,
});

export const createMessageButton = ({
  text = '',
  label = `${i18n.t('glossary.button')} #1`,
  key,
  tagList = [],
}: {
  text?: string;
  label?: string;
  key: string;
  tagList?: Array<string>;
}): {
  button: ButtonWithKey;
  action: TagAction;
} => ({
  button: {
    key,
    type: 'button',
    action: { type: 'message', text, label },
  },
  action: createTagAction({ key }, tagList),
});

export const createUriButton = ({
  uri = '',
  label = '',
  key,
  tagList = [],
}: {
  uri?: string;
  label?: string;
  key: string;
  tagList?: Array<string>;
}): {
  button: ButtonWithKey;
  parameter: LinkParameter;
} => ({
  button: {
    key,
    type: 'button',
    action: { type: 'uri', uri: key, label },
  },
  parameter: createLinkParameter(key, { url: uri, tag_list: tagList }),
});

export const createCustomParameterUriButton = ({
  label = '',
  key,
}: {
  uri?: string;
  label?: string;
  key: string;
  tagList?: Array<string>;
}): {
  button: ButtonWithKey;
  parameter: CustomParameter;
} => ({
  button: {
    key,
    type: 'button',
    action: { type: 'uri', uri: key, label },
  },
  parameter: createCustomParameter('', key),
});

export const createShareButtonParameterButton = ({
  label = '',
  key,
}: {
  label?: string;
  key: string;
}): {
  button: ButtonWithKey;
  parameter: ShareButtonParameter;
} => ({
  button: {
    key,
    type: 'button',
    action: { type: 'uri', uri: key, label },
  },
  parameter: createShareButtonParameter(key),
});

const firstButton = createMessageButton({
  key: generateMappingKey('messageButton'),
  label: `${i18n.t('glossary.button')} #1`,
});

/**
 * The state of Card
 * @see https://immerjs.github.io/immer/complex-objects
 */
export class CardData implements CardDataType {
  [immerable] = true;
  notification_text = '';
  /** Used for Card Title and Description for RichTexts */
  format: [ParamRichTextFormatData, ParamRichTextFormatData] = [
    createParamRichTextFormat({ richEditorId: generateRichEditorId(cardTitleIndex) }),
    createParamRichTextFormat({ richEditorId: generateRichEditorId(cardDescriptionIndex) }),
  ];
  contents: CardDataType['contents'] = {
    type: 'bubble',
    direction: 'ltr',
    body: {
      type: 'box',
      layout: 'vertical',
      contents: [
        {
          type: 'text',
          text: '',
          weight: 'bold',
          color: theme.colors.neutral010,
          wrap: true,
        },
        {
          type: 'text',
          text: '',
          wrap: true,
          color: theme.colors.neutral009,
          offsetTop: '8px',
        },
      ],
    },
    footer: {
      type: 'box',
      layout: 'vertical',
      spacing: 'none',
      contents: [
        createMessageButton({
          key: generateMappingKey('messageButton'),
          label: `${i18n.t('glossary.button')} #1`,
        }).button,
      ],
    },
  };

  constructor({ richEditorIds, ...props }: Partial<CardData & CardRichEditorIdsInjection> = {}) {
    Object.assign(this, props);
    if (richEditorIds) {
      this.format = [
        createParamRichTextFormat({ richEditorId: richEditorIds[cardTitleIndex] }),
        createParamRichTextFormat({ richEditorId: richEditorIds[cardDescriptionIndex] }),
      ];
    }
  }

  getCardData(): CardData['contents'] {
    return this.contents;
  }

  addButton(button: ButtonWithKey): void {
    this.contents.footer.contents.push(button);
  }

  deleteButton(key: string): void {
    this.contents.footer.contents = this.contents.footer.contents.filter((c) => c.key !== key);
  }

  /**
   * Body should have a title and descriptions
   * @param i - 0 is title, 1 is description
   * @param value - the value of the title or description
   */
  onBodyTextChange(i: number, value: string): void {
    (this.contents.body.contents[i] as FlexText).text = value;
  }

  /**
   * Set the URL of the Card hero image.
   *
   * @param url - The URL of the hero image.
   * @param aspectRatio - The aspect ratio of the hero image.
   * @param isAnimated - Whether the hero image is animated.
   */
  setUrl(url: string, aspectRatio: string, isAnimated: boolean): void {
    if (this.contents.hero) {
      this.contents.hero.url = url;
      this.contents.hero.aspectRatio = aspectRatio;
      this.contents.hero.animated = isAnimated;
    } else {
      this.contents.hero = {
        type: 'image',
        url,
        size: 'full',
        aspectRatio: aspectRatio,
        aspectMode: 'cover',
        animated: isAnimated,
      };
    }
  }

  setUrlWithKey(isParam: boolean, url: string, aspectRatio: string, isAnimated: boolean): void {
    if (this.contents.hero) {
      this.contents.hero.url = url;
      this.contents.hero.key = isParam ? this.contents.hero.key : '';
      this.contents.hero.aspectRatio = aspectRatio;
      this.contents.hero.animated = isAnimated;
    } else {
      this.contents.hero = {
        type: 'image',
        url,
        key: '',
        size: 'full',
        aspectRatio: aspectRatio,
        aspectMode: 'cover',
        animated: isAnimated,
      };
    }
  }

  setMappingKey(mappingKey = ''): void {
    if (!this.contents.hero) {
      return;
    }
    this.contents.hero.key = mappingKey;
  }

  getFooterButton(i: number): ButtonWithKey {
    return this.contents.footer.contents[i];
  }

  setFooterButton(i: number, button: ButtonWithKey): void {
    this.contents.footer.contents[i] = button;
  }

  /**
   * Change the Footer Button label
   * @param i - Button index
   * @param value - The value of the label
   */
  onActionLabelChange(i: number, value: string): void {
    const target = this.getFooterButton(i);
    target.action.label = value;
  }
}

export class CardEditorData
  implements
    AbstractEditorData<
      CardDataType,
      ParamRichTextFormat,
      TagActionData,
      | LinkParameterData
      | CustomParameterData
      | ParamRichTextParameterData
      | ShareButtonParameterData
    >
{
  [immerable] = true;
  module_id: LineFieldType.Card = 4;
  data = new CardData();
  /** Used for URI or Custom URI buttons */
  parameters: Array<
    LinkParameter | CustomParameter | ParamRichTextParameter | ShareButtonParameter
  > = [];
  /** Used for Message buttons */
  actions = [firstButton.action];
  quick_reply = { items: [] };
  format: ParamRichTextFormat['format'] = { tagId: 0 };

  constructor({
    data,
    richEditorIds,
    ...props
  }: Partial<CardEditorData & CardRichEditorIdsInjection> = {}) {
    Object.assign(this, props);
    this.data = new CardData({ ...(data ?? {}), richEditorIds });
  }

  addButton(): void {
    const index = this.data.contents.footer.contents.length + 1;
    const newButton = createMessageButton({
      label: `${i18n.t('glossary.button')} #${index}`,
      key: generateMappingKey('messageButton'),
    });
    this.data.addButton(newButton.button);
    this.actions.push(newButton.action);
  }

  deleteButton(key: string): void {
    this.data.deleteButton(key);
    this.actions = this.actions.filter((action) => action.key !== key);
    this.parameters = this.parameters.filter((p) => p.key !== key && p.mappingKey !== key);
  }

  hasParameterButtonType(buttonType: CardButtonType): boolean {
    return ['uri', 'customUri', 'shareButton'].includes(buttonType);
  }

  /**
   * Remove parameter by key when switching action type
   *
   * @param key - the original key of footer button
   * @param previousType - previous type of footer button
   */
  removeOldParameters(key: string, previousType: CardButtonType): void {
    if (previousType === 'message') {
      this.actions = this.actions.filter((a) => a.key !== key);
    }
    if (this.hasParameterButtonType(previousType)) {
      this.parameters = this.parameters.filter((p) => p.key !== key && p.mappingKey !== key);
    }
  }

  switchFooterActionType(index: number, type: CardButtonType, previousType: CardButtonType): void {
    // Should remove old parameters before switching type
    const currentButton = this.data.contents.footer.contents[index];
    this.removeOldParameters(currentButton.key, previousType);

    switch (type) {
      case 'message':
        this.toMessageButton(index);
        break;
      case 'uri':
        this.toUriButton(index);
        break;
      case 'customUri':
        this.toCustomUriButton(index);
        break;
      case 'shareButton':
        this.toShareButton(index);
        break;
      default:
        break;
    }
  }

  /**
   * convert button to uri button.
   * the message text data will be lost.
   */
  toUriButton(index: number): void {
    const currentButton = this.data.contents.footer.contents[index];
    const uriButton = createUriButton({
      label: currentButton.action.label,
      key: generateMappingKey('standardLink'),
    });

    // Change currentButton to uriButton
    this.data.contents.footer.contents[index] = uriButton.button;
    this.parameters.push(uriButton.parameter);
  }

  /**
   * convert button to message button.
   * uri data will be lost.
   */
  toMessageButton(index: number): void {
    const currentButton = this.data.contents.footer.contents[index];
    const messageButton = createMessageButton({
      label: currentButton.action.label,
      key: generateMappingKey('messageButton'),
    });

    // Change currentButton to messageButton
    this.data.contents.footer.contents[index] = messageButton.button;
    this.actions.push(messageButton.action);
  }

  /**
   * convert button to customUri button.
   * the message text data will be lost.
   */
  toCustomUriButton(index: number): void {
    const currentButton = this.data.contents.footer.contents[index];
    const customUriButton = createCustomParameterUriButton({
      label: currentButton.action.label,
      key: generateMappingKey('customUri'),
    });

    // Change currentButton to customUriButton
    this.data.contents.footer.contents[index] = customUriButton.button;
    this.parameters.push(customUriButton.parameter);
  }

  toShareButton(index: number): void {
    const currentButton = this.data.contents.footer.contents[index];
    const shareButton = createShareButtonParameterButton({
      label: currentButton.action.label,
      key: generateMappingKey('shareButton'),
    });

    this.data.contents.footer.contents[index] = shareButton.button;
    this.parameters.push(shareButton.parameter);
  }

  /**
   * Set tagList to specify id action
   * @param key - The unique key by uri or message
   * @param tagList - The tag list
   */
  setTag(key: string, tagList: Array<string>): void {
    if (key.includes('message')) {
      this.actions.forEach((action) => {
        if (action.data.function === 'tag' && action.key === key) {
          action.data.tag_list = tagList;
        }
      });
    }
    if (
      key.includes('uri') ||
      isMappingKey('standardLink', key) ||
      isMappingKey('shareButton', key)
    ) {
      this.parameters.forEach((parameter) => {
        if (
          (parameter.data.function === 'link' || parameter.data.function === 'sharelink') &&
          parameter.key === key
        ) {
          parameter.data.tag_list = tagList;
        }
      });
    }
  }

  /**
   * Set hero image url
   */
  setUrl(url: string, aspectRatio: string, isAnimated: boolean): void {
    this.data.setUrl(url, aspectRatio, isAnimated);
  }

  setMappingKey(mappingKey = ''): void {
    this.data.setMappingKey(mappingKey);
  }

  setUrlWithKey(isParam: boolean, url: string, aspectRatio: string, isAnimated: boolean): void {
    this.data.setUrlWithKey(isParam, url, aspectRatio, isAnimated);
    if (isParam) {
      this.heroSourceToParam(url);
    } else {
      this.heroSourceToLocal(url, aspectRatio, isAnimated);
    }
  }

  switchHeroSourceType(currentType: ImageSourceType, previousType: ImageSourceType): void {
    if (currentType === previousType) {
      return;
    }
    if (currentType === 'local') {
      this.heroSourceToLocal('', '', false);
    }
    if (currentType === 'param') {
      this.heroSourceToParam('');
    }
  }

  private heroSourceToLocal(url = '', aspectRatio: string, isAnimated: boolean): void {
    this.parameters = this.parameters.filter((p) => p.mappingKey !== this.data.contents.hero?.key);
    this.setUrl(url, aspectRatio, isAnimated);
    this.setMappingKey('');
  }

  private heroSourceToParam(key: string): void {
    this.setUrl(PARAM_PLACEHOLDER.image, '1:1', false);
    const customHeroParameterIndex = this.parameters.findIndex(
      (p) => isCustomParameter(p) && p.mappingKey === this.data.contents.hero?.key,
    );
    if (customHeroParameterIndex !== -1) {
      // Update the existing param hero
      this.parameters[customHeroParameterIndex].key = key;
    } else {
      // Create a new param hero
      const mappingKey = generateMappingKey('customHero');
      this.setMappingKey(mappingKey);
      this.parameters.push(createCustomParameter(key, mappingKey));
    }
  }

  setNotificationText(text: string): void {
    this.data.notification_text = text;
  }

  setMessage(index: number, value: string): void {
    const button = this.data.getFooterButton(index);
    if (button.action.type === 'message') {
      const action = this.findActionByKey(button.key);
      if (action) {
        action.trigger_code = value;
      }
      button.action.text = value;
    }
  }

  setUri(index: number, value: string, openExternal: boolean, utm: Utm): void {
    const key = this.getKey(index);
    const parameter = this.findParameterByKey(key);
    if (parameter && isLinkParameter(parameter)) {
      parameter.data.url = value;
      parameter.data.open_external_browser = openExternal;
      parameter.data.utm_source = utm.utm_source;
      parameter.data.utm_medium = utm.utm_medium;
      parameter.data.utm_content = utm.utm_content;
      parameter.data.utm_campaign = utm.utm_campaign;
    }
  }

  setCustomUri(index: number, value: string): void {
    const mappingKey = this.getKey(index);
    const parameter = this.findParameterByMappingKey(mappingKey);

    if (parameter) {
      parameter.key = value;
    }
  }

  setShareButton(index: number, value: string): void {
    const key = this.getKey(index);
    const parameter = this.findParameterByKey(key);
    if (parameter && isShareButtonParameter(parameter)) {
      parameter.data.name = value;
    }
  }

  getKey(index: number): string {
    return this.data.getFooterButton(index).key;
  }

  private findActionByKey(key: string) {
    return this.actions.find((action) => action.key === key);
  }

  private findParameterByKey(key: string) {
    return this.parameters.find((param) => param.key === key);
  }

  private findParameterByMappingKey(key: string) {
    return this.parameters.find((param) => param.mappingKey === key);
  }
}
