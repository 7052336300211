/** Preview Enum */
export enum LineFieldType {
  // Preview PNP sms message
  SMS = 0,
  Text = 1,
  Image = 2,
  ImageMap = 3,
  Card = 4,
  Carrousel = 5,
  Video = 8,
  ImageCarousel = 9,
  // skip 10, 11 used in staging
  News = 12,
  Prize = 13,
  Flex = 100001,
  ImageMapFlex = 14,
  ImageMapCarousel = 15,
  Retarget = 16,
}
