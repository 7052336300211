export const LineEditorParameterDataFunctionEnum = {
  Link: 'link',
  MemberName: 'member_name',
  Sharelink: 'sharelink',
  PrizeName: 'prize_name',
  PrizeLiffPage: 'prize_liff_page',
  BindLink: 'bind_link',
  Tag: 'tag',
  ReferralInviter: 'referral_inviter',
  ReferralInvitee: 'referral_invitee',
  ReferralActivityLink: 'referral_activity_link',
  ReferralInvitationLink: 'referral_invitation_link',
  ProductName: 'product_name',
  ProductPrice: 'product_price',
  ProductLink: 'product_link',
  ProductImageLink: 'product_image_link',
  RetargetCards: 'retarget_cards',
} as const;

export const LineEditorParameterTypeEnum = {
  Standard: 'standard',
  Object: 'object',
  CustomString: 'custom_string',
  CustomObject: 'custom_object',
} as const;

export const LineEditorTextModuleTagTypeEnum = {
  Url: 'url',
  Name: 'name',
  Custom: 'custom',
  ReferralInviter: 'referral_inviter',
  ReferralInvitee: 'referral_invitee',
  ReferralActivityLink: 'referral_activity_link',
  ReferralInvitationLink: 'referral_invitation_link',
  ProductName: 'product_name',
  ProductPrice: 'product_price',
} as const;

export type LineEditorTextModuleTagType =
  (typeof LineEditorTextModuleTagTypeEnum)[keyof typeof LineEditorTextModuleTagTypeEnum];

// Note: these keys are case sensitive and need to remain as they are!
export const LineEditorDefaultMappingKeyEnum = {
  customImage: 'customImage',
  customHero: 'customHero',
  customText: 'customText',
  customUri: 'customUri',
  standardLink: 'standardLink',
  messageButton: 'messageButton',
  shareButton: 'shareButton',
  productName: 'productName',
  productPrice: 'productPrice',
  productLink: 'productLink',
  productImageLink: 'productImageLink',
} as const;

export const LineEditorMessageModuleTypeEnum = {
  Sms: 0,
  Text: 1,
  Image: 2,
  Imagemap: 3,
  Card: 4,
  Carousel: 5,
  Video: 8,
  ImageCarousel: 9,
  News: 12,
  Prize: 13,
  Flex: 100001,
  ImagemapFlex: 14,
  ImagemapCarousel: 15,
  Retarget: 16,
} as const;

export type LineEditorMessageModuleType =
  (typeof LineEditorMessageModuleTypeEnum)[keyof typeof LineEditorMessageModuleTypeEnum];

export const LineEditorModuleTypeNameMapEnum = {
  [LineEditorMessageModuleTypeEnum.Sms]: 'SMS',
  [LineEditorMessageModuleTypeEnum.Text]: 'Text',
  [LineEditorMessageModuleTypeEnum.Image]: 'Image',
  [LineEditorMessageModuleTypeEnum.Imagemap]: 'Imagemap',
  [LineEditorMessageModuleTypeEnum.Card]: 'Card',
  [LineEditorMessageModuleTypeEnum.Carousel]: 'Carousel',
  [LineEditorMessageModuleTypeEnum.Video]: 'Video',
  [LineEditorMessageModuleTypeEnum.ImageCarousel]: 'Image Carousel',
  [LineEditorMessageModuleTypeEnum.News]: 'Breaking News',
  [LineEditorMessageModuleTypeEnum.Prize]: 'Prize',
  [LineEditorMessageModuleTypeEnum.Flex]: 'Flex',
  [LineEditorMessageModuleTypeEnum.ImagemapFlex]: 'Imagemap Flex',
  [LineEditorMessageModuleTypeEnum.ImagemapCarousel]: 'Imagemap Carousel',
  [LineEditorMessageModuleTypeEnum.Retarget]: 'Retarget',
} as const;
