import { createContext, useContext, useMemo } from 'react';

import type { GoogleAnalyticSetting } from 'components/Types';
import type { ActionTypes, LoginSsoVariable, UserData } from 'context/authContext/types';
import type { BotInfo, FlatChannel, Organization, SwitchDetail, User } from 'layout/types';
import type { SwitchNames } from 'shared/models/organization';

export interface State {
  channels: Array<FlatChannel>;
  user: User | null;
  org: Organization | null;
  currentChannel: FlatChannel | null;
  ga: Array<GoogleAnalyticSetting>;
  currentBotInfo: BotInfo | null;
  switches: SwitchDetail;
  isCaacActivated: boolean;
}

export interface AuthContextProps {
  state: State;
  dispatch: (action: ActionTypes) => void;
  loginWithSso: (variable: LoginSsoVariable) => Promise<void | UserData>;
  logout: () => Promise<void | UserData>;
  reload: () => void;
  isLoading: boolean;
}

export const initValue: State = {
  channels: [],
  user: null,
  org: null,
  currentChannel: null,
  ga: [],
  currentBotInfo: null,
  switches: {},
  isCaacActivated: false,
};

const AuthContext = createContext<AuthContextProps | undefined>(undefined);

const useAuth = (): AuthContextProps => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthContextProvider');
  }
  return context;
};

const useIsSwitchActive = (switchName: SwitchNames): boolean => {
  const {
    state: { switches },
  } = useAuth();
  return useMemo(() => !!switches[switchName], [switchName, switches]);
};

const useUser = (): User | null => {
  const context = useAuth();
  return context.state.user;
};

const useCurrentUser = (): User => {
  const user = useUser();
  if (!user) {
    throw new Error(`user must be set`);
  }
  return user;
};

const useCurrentChannel = (): FlatChannel => {
  const {
    state: { currentChannel },
  } = useAuth();
  if (!currentChannel) {
    throw new Error(`current channel must be set`);
  }
  return currentChannel;
};

/**
 * 這邊處理未綁定 bot 無 channel 時的狀況
 */
const useCurrentChannelNullable = (): FlatChannel | null => {
  const {
    state: { currentChannel },
  } = useAuth();
  return currentChannel;
};

const useOrganization = (): Organization => {
  const {
    state: { org },
  } = useAuth();
  if (!org) {
    throw new Error(`Organization must be set`);
  }
  return org;
};

const useSwitches = (): SwitchDetail => {
  const {
    state: { switches },
  } = useAuth();

  if (!switches) {
    throw new Error(`Switches must be set`);
  }
  return switches;
};

const useBotId = (): number => {
  const currentChannel = useCurrentChannel();
  return currentChannel.id;
};

const useGaSettings = (): { gaSettings: Array<GoogleAnalyticSetting>; withGaSettings: boolean } => {
  const {
    state: { ga },
  } = useAuth();
  if (!ga) {
    throw new Error(`ga must be set in the AuthContext`);
  }

  return {
    gaSettings: ga,
    withGaSettings: ga.length > 0,
  };
};

export {
  AuthContext,
  useAuth,
  useBotId,
  useCurrentChannel,
  useCurrentChannelNullable,
  useCurrentUser,
  useIsSwitchActive,
  useOrganization,
  useSwitches,
  useUser,
  useGaSettings,
};
