import { useFeatureFlag } from 'features/featureFlag';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import type { BotInfo } from 'layout/types';

import { useAuth } from 'context/authContext';

export const useServerRegion = (): {
  isJapanServer: boolean;
  isTaiwanServer: boolean;
  serverRegion: BotInfo['server_region'];
  shortLinkPreviewText: string;
} => {
  const { t } = useTranslation();

  const {
    state: { currentBotInfo },
  } = useAuth();
  const enabledServerRegion = useFeatureFlag('enabled-server-region');
  const serverRegion = currentBotInfo?.server_region ?? 'tw';

  const isJapanServer = useMemo(
    () => (enabledServerRegion !== null ? enabledServerRegion === 'jp' : serverRegion === 'jp'),
    [enabledServerRegion, serverRegion],
  );

  const isTaiwanServer = useMemo(
    () =>
      serverRegion === 'tw' && (enabledServerRegion === 'global' || enabledServerRegion === null),
    [serverRegion, enabledServerRegion],
  );

  const shortLinkPreviewText = useMemo(
    () =>
      isJapanServer
        ? t('message.editor.preview.shortLink.jp.label')
        : t('message.editor.preview.shortLink.label'),
    [isJapanServer, t],
  );

  return {
    isJapanServer,
    isTaiwanServer,
    serverRegion,
    shortLinkPreviewText,
  };
};
