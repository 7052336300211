import type {
  ImagemapContentBox,
  ImagemapContents,
  ImagemapFlexModule,
  ImagemapModule,
} from 'shared/components/Editor/LineMessageEditor/types';

import { LineEditorMessageModuleTypeEnum } from 'shared/models/editor/lineEditor';

export const parseImagemapToFlex = (imagemap: ImagemapModule): ImagemapFlexModule => {
  const { module_id, data, actions, ...rest } = imagemap;
  const { height, width } = data.base_size;

  const imagemapContentBoxes: Array<ImagemapContentBox> = data.actions.map((action) => ({
    type: 'box',
    layout: 'vertical',
    contents: [],
    position: 'absolute',
    width: `${((action.area.width / width) * 100).toFixed(2)}%`,
    height: `${((action.area.height / height) * 100).toFixed(2)}%`,
    offsetTop: `${((action.area.y / height) * 100).toFixed(2)}%`,
    offsetStart: `${((action.area.x / width) * 100).toFixed(2)}%`,
    action:
      action.type === 'message'
        ? { type: 'message', text: action.text }
        : { type: 'uri', uri: action.linkUri },
  }));

  const contents: ImagemapContents = {
    type: 'bubble',
    size: 'giga',
    body: {
      type: 'box',
      layout: 'vertical',
      paddingAll: '0px',
      contents: [
        {
          type: 'image',
          url: data.base_url,
          size: 'full',
          aspectMode: 'cover',
          aspectRatio: `${width}:${height}`,
          gravity: 'center',
          animated: data.animated,
        },
        ...imagemapContentBoxes,
      ],
    },
  };

  return {
    ...rest,
    module_id: LineEditorMessageModuleTypeEnum.ImagemapFlex,
    data: {
      notification_text: data.notification_text,
      contents,
    },
    actions: [],
  };
};
