import type { Message } from 'shared/components/Editor/LineMessageEditor/types';

import { LineEditorMessageModuleTypeEnum } from 'shared/models/editor/lineEditor';

export const shareableMessageValidator = (messages: Array<Message>) => {
  const hasShareButtonModule = messages.some(
    (message) =>
      message.module_id === LineEditorMessageModuleTypeEnum.Card ||
      message.module_id === LineEditorMessageModuleTypeEnum.Carousel,
  );

  if (!hasShareButtonModule) {
    return {
      hasShareButton: false,
      hasShareButtonConflict: false,
    };
  }

  let hasSendMessageAction = false;
  let hasShareButton = false;
  let hasNonSharableModule = false;

  for (const message of messages) {
    switch (message.module_id) {
      case LineEditorMessageModuleTypeEnum.Card:
        if (message.data.contents.hero?.action?.type === 'message') {
          hasSendMessageAction = true;
        }
        const cardFooterContents = message.data.contents.footer.contents;
        for (const content of cardFooterContents) {
          if (content.action.type === 'message') {
            hasSendMessageAction = true;
          }
          if (content.key.startsWith('shareButton')) {
            hasShareButton = true;
          }
        }
        break;
      case LineEditorMessageModuleTypeEnum.Carousel:
        const carouselContents = message.data.contents;
        for (const outerContent of carouselContents) {
          if (outerContent.contents.hero?.action?.type === 'message') {
            hasSendMessageAction = true;
          }
          const innerFooterContents = outerContent.contents.footer.contents;
          for (const innerContent of innerFooterContents) {
            if (innerContent.action.type === 'message') {
              hasSendMessageAction = true;
            }
            if (innerContent.key.startsWith('shareButton')) {
              hasShareButton = true;
            }
          }
        }
        break;
      case LineEditorMessageModuleTypeEnum.ImageCarousel:
        const imageCarouselContents = message.data.contents;
        for (const content of imageCarouselContents) {
          if (content.hero.action.type === 'message') {
            hasSendMessageAction = true;
          }
        }
        break;
      case LineEditorMessageModuleTypeEnum.ImagemapCarousel:
        const imagemapCarouselContents = message.data.contents.contents;
        for (const outerContent of imagemapCarouselContents) {
          for (const innerContent of outerContent.body.contents) {
            if (innerContent.type === 'box' && innerContent?.action?.type === 'message') {
              hasSendMessageAction = true;
            }
          }
        }
        break;
      case LineEditorMessageModuleTypeEnum.Prize:
      case LineEditorMessageModuleTypeEnum.Video:
      case LineEditorMessageModuleTypeEnum.Imagemap:
      case LineEditorMessageModuleTypeEnum.ImagemapFlex:
        hasNonSharableModule = true;
        break;
      default:
        break;
    }
  }

  return {
    hasShareButton,
    hasShareButtonConflict: hasShareButton && (hasSendMessageAction || hasNonSharableModule),
  };
};
