import { toNumber } from '@chatbotgang/etude/pitch-shifter/number';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { omit } from 'remeda';

import { defaultCardHero } from 'shared/components/Editor/LineMessageEditor/components/CardModule/constants';
import {
  PARAM_PLACEHOLDER,
  RETARGET_PLACEHOLDER,
} from 'shared/components/Editor/LineMessageEditor/constants';

import type {
  CardContent,
  LineMessageAction,
  LineMessageEditorInput,
  Parameter,
} from 'shared/components/Editor/LineMessageEditor/types';

import { logError } from 'lib/logger';
import { useFormContext } from 'lib/react-hook-form/useFormContext';
import {
  findSmallestAspectRatio,
  parseWidthAndHeight,
} from 'shared/components/Editor/LineMessageEditor/components/ImagemapCarouselModule/utils';
import { parseFlexToImagemap } from 'shared/components/Editor/LineMessageEditor/components/ImagemapModule/utils/parseFlexToImagemap';
import { parseImagemapToFlex } from 'shared/components/Editor/LineMessageEditor/components/ImagemapModule/utils/parseImagemapToFlex';
import { useEnableTagMigration } from 'shared/components/Editor/LineMessageEditor/hooks/useEnableTagMigration';
import { LineMessageEditorSchema } from 'shared/components/Editor/LineMessageEditor/types';
import {
  isCardModule,
  isCarouselModule,
  isCustomHeroParameter,
  isCustomImageParameter,
  isCustomLinkParameter,
  isCustomParameter,
  isCustomParameterWithLink,
  isCustomParameterWithSharelink,
  isCustomUriAction,
  isCustomUriParameter,
  isImageCarouselModule,
  isImagemapCarouselModule,
  isImagemapFlexModule,
  isImagemapModule,
  isImageModule,
  isProductImageLinkParameter,
  isProductLinkParameter,
  isRetargetModule,
  isStandardParameter,
  isTextModule,
  isUriAction,
} from 'shared/components/Editor/LineMessageEditor/utils/helper';
import { shareableMessageValidator } from 'shared/components/Editor/LineMessageEditor/utils/shareableMessageValidator';
import { composeKey, transformKey } from 'shared/components/Editor/utils/helper';
import { useTagMetadata } from 'shared/hooks/tag/useTagMetadata';
import { useMessage } from 'shared/hooks/ui/useMessage';
import { LineEditorMessageModuleTypeEnum } from 'shared/models/editor/lineEditor';

const replaceCardTextWithMappingKey = (cardContent: CardContent, parameters: Array<Parameter>) => {
  const [card, description] = cardContent.body.contents;
  parameters.filter(isCustomParameter).forEach((parameter) => {
    if (card.text.includes(composeKey(parameter.mappingKey))) {
      cardContent.body.contents[0].text = card.text.replace(
        composeKey(parameter.mappingKey),
        composeKey(parameter.key),
      );
    }
    if (description.text.includes(composeKey(parameter.mappingKey))) {
      cardContent.body.contents[1].text = description.text.replace(
        composeKey(parameter.mappingKey),
        composeKey(parameter.key),
      );
    }
  });
  return cardContent;
};

const filteredEveryParameter = (parameter: Array<Parameter>) => ({
  customImages: parameter.filter(isCustomImageParameter),
  customLinks: parameter.filter(isCustomLinkParameter),
  customUris: parameter.filter(isCustomUriParameter),
});

const filterUriParameters = (parameters: Array<Parameter>) =>
  parameters.filter(
    (parameter) =>
      (isStandardParameter(parameter) &&
        (parameter.key.startsWith('shareButton') || parameter.key.startsWith('standardLink'))) ||
      (isCustomParameter(parameter) &&
        (parameter.mappingKey.startsWith('customUri') ||
          parameter.mappingKey.startsWith('productLink'))),
  );

export const transformServerMessageToUiFormat = (messages: LineMessageEditorInput['messages']) => {
  return messages.map((message) => {
    const updatedParameters = message.parameters
      // Remove the custom parameter with link if the url is empty
      .filter((parameter) =>
        // Skip the news module since the parameter is fetch from the server.
        message.module_id !== LineEditorMessageModuleTypeEnum.News &&
        isStandardParameter(parameter) &&
        isCustomParameterWithLink(parameter?.data)
          ? parameter?.data.url !== ''
          : true,
      )
      // back fill the isUtmTracked field for the custom parameter with link
      .map((parameter) => {
        if (isStandardParameter(parameter) && isCustomParameterWithLink(parameter?.data)) {
          return {
            ...parameter,
            data: {
              ...parameter.data,
              isUtmTracked:
                (typeof parameter.data.isUtmTracked === 'boolean'
                  ? parameter.data.isUtmTracked
                  : (parameter.data?.utm_source || '').length > 0) ?? false,
            },
          };
        }
        return parameter;
      });

    message.parameters = updatedParameters;

    if (isTextModule(message)) {
      let convertText = message.data.text;
      message.parameters.filter(isCustomParameter).forEach((parameter) => {
        convertText = convertText.replace(
          composeKey(parameter.key),
          composeKey(parameter.mappingKey),
        );
      });
      return {
        ...message,
        data: {
          ...message.data,
          text: convertText,
        },
      };
    }
    if (isImageModule(message)) {
      const customImage = message.parameters.find(isCustomImageParameter);
      if (customImage?.mappingKey) {
        message.data.content_url = PARAM_PLACEHOLDER.image;
        message.data.key = customImage.mappingKey;
      }
      return message;
    }
    if (isImageCarouselModule(message)) {
      const { customImages, customLinks, customUris } = filteredEveryParameter(message.parameters);

      message.data.contents = message.data.contents.map((content) => {
        const mappedCustomImage = customImages.find(
          (customImage) => customImage.mappingKey === content.hero.contents[0].key,
        );
        if (mappedCustomImage?.mappingKey) {
          content.hero.contents[0].url = PARAM_PLACEHOLDER.image;
          content.hero.contents[0].key = mappedCustomImage.mappingKey;
        }

        const mappedCustomLink = customLinks.find(
          (customLink) =>
            isUriAction(content.hero.action) &&
            customLink.key === transformKey(content.hero.action.uri),
        );
        if (mappedCustomLink?.key && isUriAction(content.hero.action)) {
          content.hero.action.type = 'uri';
          content.hero.action.uri = mappedCustomLink.key;
        }

        const mappedCustomUri = customUris.find(
          (customUri) =>
            isCustomUriAction(content.hero.action) &&
            customUri.mappingKey === content.hero.action.key,
        );
        if (mappedCustomUri?.mappingKey && isCustomUriAction(content.hero.action)) {
          // Convert to UI supported type of `customUri` instead of keeping the type `uri`.
          content.hero.action.type = 'customUri';
          content.hero.action.uri = mappedCustomUri.key;
          content.hero.action.key = mappedCustomUri.mappingKey;
        }
        return content;
      });
    }
    if (isImagemapModule(message)) {
      const imagemap = isImagemapFlexModule(message) ? parseFlexToImagemap(message) : message;
      const actions = imagemap.data.actions.map((action) => {
        if (action.type === 'uri') {
          const customParameter = message.parameters.find(
            (parameter) => parameter.key === transformKey(action.linkUri),
          );
          if (customParameter && isCustomParameter(customParameter)) {
            return {
              ...action,
              linkUri: action.linkUri.replace(customParameter.key, customParameter.mappingKey),
            };
          }
          return action;
        }
        return action;
      });
      return {
        ...imagemap,
        data: { ...imagemap.data, actions },
        activeBlockIndex: 1,
      };
    }
    if (isImagemapCarouselModule(message)) {
      const smallestAspectRatio = findSmallestAspectRatio(message);

      // Backfill the customBlocks array if it's not the same length as the contents array
      if (message.customBlocks.length !== message.data.contents.contents.length) {
        message.data.contents.contents.map((_, index) => {
          if (message.customBlocks[index] === undefined) {
            message.customBlocks[index] = false;
          }
        });
      }

      return {
        ...message,
        data: {
          ...message.data,
          contents: {
            ...message.data.contents,
            contents: message.data.contents.contents.map((imagemap) => {
              const [imagemapContentImage, ...imagemapContentBoxes] = imagemap.body.contents;

              // Calculate the resize rate based on the smallest aspect ratio value
              const { aspectRatioValue } = parseWidthAndHeight(
                imagemapContentImage.aspectRatio || '1:1',
              );
              const resizeRate = smallestAspectRatio / aspectRatioValue;

              imagemap.body.contents = [
                imagemapContentImage,
                ...imagemapContentBoxes.map(({ action, height, offsetTop, ...rest }) => {
                  /*
                   * Resize the height and offsetTop of the image map action block.
                   * Since we do the resizing before sending data to backend to comply LINE's spec, we need to covert them back to the original value after receiving data from backend.
                   */
                  const resizedBlock = {
                    ...rest,
                    height: height
                      ? `${(
                          toNumber(height.replace('%', ''), { defaultValue: 0 }) / resizeRate
                        ).toFixed(2)}%`
                      : undefined,
                    offsetTop: offsetTop
                      ? `${(
                          toNumber(offsetTop.replace('%', ''), { defaultValue: 0 }) / resizeRate
                        ).toFixed(2)}%`
                      : undefined,
                  };
                  if (
                    isUriAction(action) &&
                    action.key &&
                    transformKey(action.key).startsWith('customUri')
                  ) {
                    return {
                      ...resizedBlock,
                      action: {
                        ...action,
                        // Convert action type "uri" to "customUri" since we use "customUri" in UI logic to distinguish URI and custom URI
                        type: 'customUri' as const,
                      },
                    };
                  }
                  return {
                    ...resizedBlock,
                    action,
                  };
                }),
              ];

              return imagemap;
            }),
          },
        },
      };
    }
    if (isCardModule(message)) {
      const [card, description] = message.data.contents.body.contents;
      // Replace the custom parameter key with the mapping key
      message.parameters.filter(isCustomParameter).forEach((parameter) => {
        if (card.text.includes(composeKey(parameter.key))) {
          card.text = card.text.replace(
            composeKey(parameter.key),
            composeKey(parameter.mappingKey),
          );
        }
        if (description.text.includes(composeKey(parameter.key))) {
          description.text = description.text.replace(
            composeKey(parameter.key),
            composeKey(parameter.mappingKey),
          );
        }
      });

      // Revert Link Parameter and/or Custom URI Parameter to plain text key name
      const uriParameters = message.parameters.filter(
        (parameter) => isCustomUriParameter(parameter) || isCustomLinkParameter(parameter),
      );
      const finalFooterContents = message.data.contents.footer.contents.map((content) => {
        if (content.action.type === 'uri') {
          const parameter = uriParameters.find(
            (parameter) => isCustomParameter(parameter) && parameter?.mappingKey === content.key,
          );
          if (parameter && isCustomParameter(parameter)) {
            content.action.uri = transformKey(parameter.key);
          }
        }
        return content;
      });
      message.data.contents.footer.contents = finalFooterContents;

      // Revert image placeholder if is custom hero
      const customHeroParameter = message.parameters.find(
        // Make compatible with the custom hero and custom image parameter.
        (parameter) => isCustomHeroParameter(parameter) || isCustomImageParameter(parameter),
      );
      if (customHeroParameter && message.data.contents.hero?.key) {
        message.data.contents.hero.url = PARAM_PLACEHOLDER.image;
        message.data.contents.hero.key = customHeroParameter.mappingKey;
      }

      const bodyAction = card.action || description.action;

      if (bodyAction) {
        const transformBodyAction =
          bodyAction.type === 'uri'
            ? ({
                ...bodyAction,
                uri: transformKey(bodyAction.uri),
                type: bodyAction.key ? 'customUri' : 'uri',
              } as const)
            : bodyAction;
        if (message.data.contents.hero) {
          message.data.contents.hero.action = transformBodyAction;
        } else {
          message.data.contents.hero = { ...defaultCardHero, action: transformBodyAction };
        }
      }
      return message;
    }

    // Basically the structure is as same as CarouselModule, with less or more parameters and action types.
    if (isRetargetModule(message)) {
      const uriParameters = filterUriParameters(message.parameters);
      message.data.contents.forEach((cardMessage, index) => {
        const finalFooterContents = cardMessage.contents.footer.contents.map((content) => {
          if (content.action.type === 'uri') {
            const parameter = uriParameters.find(
              (parameter) =>
                (isStandardParameter(parameter) && parameter.key === content.key) ||
                (isCustomParameter(parameter) && parameter.mappingKey === content.key),
            );

            if (parameter && isCustomParameter(parameter)) {
              content.action.uri = '';
              content.action.type = 'customUri';
            }
            if (parameter && isStandardParameter(parameter)) {
              content.action.uri = transformKey(parameter.key);
            }
          }
          return content;
        });
        message.data.contents[index].contents.footer.contents = finalFooterContents;

        const customProductHeroParameters = message.parameters.filter(
          (parameter) =>
            isProductLinkParameter(parameter) || isProductImageLinkParameter(parameter),
        );
        message.data.contents = message.data.contents.map((card) => {
          const mappedCustomHero = customProductHeroParameters.find(
            (parameter) =>
              isCustomParameter(parameter) && parameter.mappingKey === card.contents?.hero?.key,
          );

          if (mappedCustomHero && card.contents.hero) {
            card.contents.hero.url = RETARGET_PLACEHOLDER.hero;
            card.contents.hero.key = mappedCustomHero.mappingKey;
            return card;
          } else return card;
        });

        const [card, description] = message.data.contents[index].contents.body.contents;
        message.parameters.filter(isCustomParameter).forEach((parameter) => {
          if (card.text.includes(composeKey(parameter.key))) {
            card.text = card.text.replace(
              composeKey(parameter.key),
              composeKey(parameter.mappingKey),
            );
          }
          if (description.text.includes(composeKey(parameter.key))) {
            description.text = description.text.replace(
              composeKey(parameter.key),
              composeKey(parameter.mappingKey),
            );
          }
        });

        const bodyAction = card?.action ||
          description?.action || {
            type: 'none',
          };
        const transformBodyAction =
          bodyAction.type === 'uri'
            ? ({
                ...bodyAction,
                uri: '',
                type: 'customUri',
              } as const)
            : bodyAction;
        if (message.data.contents[index].contents?.hero) {
          message.data.contents[index].contents.hero.action = transformBodyAction;
        }
      });
      return message;
    }

    if (isCarouselModule(message)) {
      const uriParameters = message.parameters.filter(
        (parameter) => isCustomUriParameter(parameter) || isCustomLinkParameter(parameter),
      );
      message.data.contents.forEach((cardMessage, index) => {
        const finalFooterContents = cardMessage.contents.footer.contents.map((content) => {
          // Revert Link Parameter and/or Custom URI Parameter to plain text key name
          if (content.action.type === 'uri') {
            const parameter = uriParameters.find(
              (parameter) => isCustomParameter(parameter) && parameter.mappingKey === content.key,
            );
            if (
              parameter &&
              (isCustomParameterWithSharelink(parameter.data) || isCustomParameter(parameter))
            ) {
              content.action.uri = transformKey(parameter.key);
            }
          }
          return content;
        });
        message.data.contents[index].contents.footer.contents = finalFooterContents;

        // Revert image placeholder if is custom hero
        const customHeroParameters = message.parameters.filter(
          // Make compatible with the custom hero and custom image parameter.
          (parameter) => isCustomHeroParameter(parameter) || isCustomImageParameter(parameter),
        );
        message.data.contents = message.data.contents.map((card) => {
          const mappedCustomHero = customHeroParameters.find(
            (parameter) =>
              isCustomParameter(parameter) &&
              parameter.mappingKey === cardMessage.contents?.hero?.key,
          );
          const isCardMappedWithCustomHero =
            mappedCustomHero &&
            isCustomParameter(mappedCustomHero) &&
            mappedCustomHero?.mappingKey === card.contents.hero?.key;
          if (
            mappedCustomHero &&
            isCustomParameter(mappedCustomHero) &&
            mappedCustomHero?.mappingKey &&
            card.contents.hero?.key &&
            isCardMappedWithCustomHero
          ) {
            // Revert image placeholders by finding mapped custom heroes
            card.contents.hero.url = PARAM_PLACEHOLDER.image;
            card.contents.hero.key = mappedCustomHero.mappingKey;
            return card;
          } else {
            // Sanitize hero image if empty
            return card?.contents.hero?.url === ''
              ? { ...card, contents: omit(card.contents, ['hero']) }
              : card;
          }
        });

        // Replace the custom parameter key with the mapping key
        const [card, description] = message.data.contents[index].contents.body.contents;
        message.parameters.filter(isCustomParameter).forEach((parameter) => {
          if (card.text.includes(composeKey(parameter.key))) {
            card.text = card.text.replace(
              composeKey(parameter.key),
              composeKey(parameter.mappingKey),
            );
          }
          if (description.text.includes(composeKey(parameter.key))) {
            description.text = description.text.replace(
              composeKey(parameter.key),
              composeKey(parameter.mappingKey),
            );
          }
        });

        // Clone the hero action to the body action if action type is valid.
        const bodyAction = card.action || description.action;
        if (bodyAction) {
          const transformBodyAction =
            bodyAction.type === 'uri'
              ? ({
                  ...bodyAction,
                  uri: transformKey(bodyAction.uri),
                  type: bodyAction.key ? 'customUri' : 'uri',
                } as const)
              : bodyAction;
          if (message.data.contents[index].contents.hero) {
            message.data.contents[index].contents.hero.action = transformBodyAction;
          } else {
            message.data.contents[index].contents.hero = {
              ...defaultCardHero,
              action: transformBodyAction,
            };
          }
        }
      });
      return message;
    }
    return message;
  });
};

const transformUiMessageToServerFormat = ({
  messages,
  quick_reply,
  getTagIdsByNames,
  getTagNamesByIds,
  enableTagMigration,
}: {
  messages: LineMessageEditorInput['messages'];
  quick_reply?: LineMessageEditorInput['quick_reply'];
  getTagIdsByNames: (tagNames: Array<string>) => Array<number>;
  getTagNamesByIds: (tagIds: Array<number>) => Array<string>;
  enableTagMigration: boolean;
}) => {
  const updatedMessages = messages.map((message) => {
    // TODO: Remove this block and only read `tags` from server and save `tags` to server after the BE migration is done
    if (enableTagMigration) {
      // Sync `parameter[number].data.tags` to `parameter[number].data.tag_list` for tag name & tag ID migration
      message.parameters = message.parameters.map((parameter) => {
        if (
          isStandardParameter(parameter) &&
          (isCustomParameterWithLink(parameter.data) ||
            isCustomParameterWithSharelink(parameter.data))
        ) {
          return {
            ...parameter,
            data: {
              ...parameter.data,
              tag_list: getTagNamesByIds(parameter.data.tags ?? []),
            },
          };
        }
        return parameter;
      });
    } else {
      // Sync `parameter[number].data.tag_list` to `parameter[number].data.tags` for tag name & tag ID migration
      message.parameters = message.parameters.map((parameter) => {
        if (
          isStandardParameter(parameter) &&
          (isCustomParameterWithLink(parameter.data) ||
            isCustomParameterWithSharelink(parameter.data))
        ) {
          return {
            ...parameter,
            data: {
              ...parameter.data,
              tags: getTagIdsByNames(parameter.data.tag_list),
            },
          };
        }
        return parameter;
      });
    }

    if (isTextModule(message)) {
      let convertText = message.data.text;
      message.parameters.filter(isCustomParameter).forEach((parameter) => {
        const regex = new RegExp(parameter.mappingKey, 'g');
        convertText = convertText.replace(regex, parameter.key);
      });
      return {
        ...message,
        data: {
          ...message.data,
          text: convertText,
        },
      };
    }
    if (isImageModule(message)) {
      const customImage = message.parameters.find(isCustomImageParameter);
      if (customImage?.key) {
        message.data.content_url = composeKey(customImage.key);
      }
      return message;
    }
    if (isImageCarouselModule(message)) {
      const { customImages, customLinks, customUris } = filteredEveryParameter(message.parameters);

      message.data.contents = message.data.contents.map((content) => {
        const mappedCustomImage = customImages.find(
          (customImage) => customImage.mappingKey === content.hero.contents[0].key,
        );
        if (mappedCustomImage?.key) {
          content.hero.contents[0].url = composeKey(mappedCustomImage.key);
        }

        const mappedCustomLink = customLinks.find(
          (customLink) =>
            isUriAction(content.hero.action) && customLink.key === content.hero.action.uri,
        );
        if (mappedCustomLink?.key && isUriAction(content.hero.action)) {
          content.hero.action.uri = composeKey(mappedCustomLink.key);
        }

        const mappedCustomUri = customUris.find(
          (customUri) =>
            isCustomUriAction(content.hero.action) &&
            customUri.mappingKey === content.hero.action.key,
        );
        if (mappedCustomUri?.key && isCustomUriAction(content.hero.action)) {
          // Convert to LINE supported type of `uri` instead of keeping the type `customUri`.
          content.hero.action.type = 'uri';
          content.hero.action.uri = composeKey(mappedCustomUri.key);
        }

        return content;
      });
    }
    if (isImagemapModule(message)) {
      const actions = message.data.actions.map((action) => {
        if (action.type === 'uri' && transformKey(action.linkUri).startsWith('customUri')) {
          const customParameter = message.parameters.find(
            (parameter) =>
              isCustomParameter(parameter) && parameter.mappingKey === transformKey(action.linkUri),
          );
          if (customParameter && isCustomParameter(customParameter) && customParameter) {
            return {
              ...action,
              linkUri: action.linkUri.replace(customParameter.mappingKey, customParameter.key),
            };
          }
        }
        return action;
      });
      const updatedMessage = {
        ...message,
        data: {
          ...message.data,
          actions,
        },
      };
      return message.format.isFlex ? parseImagemapToFlex(updatedMessage) : updatedMessage;
    }
    if (isImagemapCarouselModule(message)) {
      const smallestAspectRatio = findSmallestAspectRatio(message);

      message.data.contents.contents = message.data.contents.contents.map((imagemap) => {
        const [imagemapContentImage, ...imagemapContentBoxes] = imagemap.body.contents;

        // Calculate the resize rate based on the smallest aspect ratio value
        const { aspectRatioValue } = parseWidthAndHeight(imagemapContentImage.aspectRatio || '1:1');
        const resizeRate = smallestAspectRatio / aspectRatioValue;

        imagemap.body.contents = [
          imagemapContentImage,
          ...imagemapContentBoxes.map(({ action, height, offsetTop, ...rest }) => {
            /*
             * Resize the height and offsetTop based on the resize rate
             * LINE stretch the container (offset base) of each image map carousel image to fit the tallest one, so we need to resize the action blocks to fit the new height
             * Should convert them back to normal value for UI after receiving the data from backend
             */
            const resizedBlock = {
              ...rest,
              height: height
                ? `${(toNumber(height.replace('%', ''), { defaultValue: 0 }) * resizeRate).toFixed(
                    2,
                  )}%`
                : undefined,
              offsetTop: offsetTop
                ? `${(
                    toNumber(offsetTop.replace('%', ''), { defaultValue: 0 }) * resizeRate
                  ).toFixed(2)}%`
                : undefined,
            };

            if (isCustomUriAction(action) && action.key?.startsWith('customUri')) {
              const customUriParameter = message.parameters.find(
                (parameter) =>
                  isCustomUriParameter(parameter) && parameter.mappingKey === action.key,
              );

              if (customUriParameter) {
                return {
                  ...resizedBlock,
                  action: {
                    ...action,
                    // Convert from "customUri" back to "uri" since LINE doesn't accept "customUri"
                    type: 'uri' as const,
                    uri: composeKey(customUriParameter.key),
                  },
                };
              }

              return { ...resizedBlock, action };
            }

            if (isUriAction(action) && action.key?.startsWith('standardLink')) {
              const linkParameter = message.parameters.find(
                (parameter) => parameter.key === action.key,
              );

              if (linkParameter) {
                return {
                  ...resizedBlock,
                  action: {
                    ...action,
                    uri: composeKey(linkParameter.key),
                  },
                };
              }

              return { ...resizedBlock, action };
            }
            return { ...resizedBlock, action };
          }),
        ];

        return imagemap;
      });

      return {
        ...message,
        // This is used in UI only. Reset all to "1".
        activeBlockIndices: Array(message.data.contents.contents.length).fill(1),
      };
    }
    if (isCardModule(message)) {
      const finalFooterContents = message.data.contents.footer.contents.map((content) => {
        if (content.action.type === 'uri') {
          // Replace Link Parameter and/or Custom URI Parameter uri with <$var:key>
          const parameter = message.parameters.find(
            (parameter) =>
              (isStandardParameter(parameter) && parameter.key === content.key) ||
              (isCustomParameter(parameter) && parameter.mappingKey === content.key),
          );
          if (parameter) {
            content.action.uri = composeKey(parameter.key);
          }
        }
        return content;
      });
      message.data.contents.footer.contents = finalFooterContents;

      // Clone the hero action to the body action if action type is valid.
      const { hero } = message.data.contents;
      const heroAction = hero?.action;

      if (heroAction) {
        const updateBodyActions = (updatedAction: LineMessageAction) => {
          message.data.contents.body.contents = message.data.contents.body.contents.map(
            (content) => ({
              ...content,
              action: updatedAction,
            }),
          );
        };

        switch (heroAction.type) {
          case 'message':
            updateBodyActions(heroAction);
            break;

          case 'uri': {
            const heroActionParameter = message.parameters.find(
              (parameter) => isStandardParameter(parameter) && parameter.key === heroAction.uri,
            );
            if (heroActionParameter) {
              heroAction.uri = composeKey(heroActionParameter.key);
              updateBodyActions(heroAction);
            }
            break;
          }

          case 'customUri': {
            const customUriHeroParameter = message.parameters.find(
              (parameter) =>
                isCustomParameter(parameter) && parameter.mappingKey === heroAction.key,
            );
            if (customUriHeroParameter) {
              heroAction.uri = composeKey(customUriHeroParameter.key);
              heroAction.type = 'uri';
              updateBodyActions(heroAction);
            }
            break;
          }
          case 'none':
            message.data.contents.hero = omit(hero, ['action']);
            message.data.contents.body.contents = message.data.contents.body.contents.map(
              (content) => omit(content, ['action']),
            );
            break;
        }
      }

      // Replace hero image url with <$var:key>
      const customHeroParameter = message.parameters.find(
        // Make compatible with the custom hero and custom image parameter.
        (parameter) => isCustomHeroParameter(parameter) || isCustomImageParameter(parameter),
      );
      if (customHeroParameter && hero?.key) {
        hero.url = composeKey(customHeroParameter.key);
      }
      if (hero?.url === '') {
        message.data.contents = omit(message.data.contents, ['hero']);
      }

      // Replace the text with <$var:key> for backend to replace with the actual value
      message.data.contents = replaceCardTextWithMappingKey(
        message.data.contents,
        message.parameters,
      );
      return message;
    }

    // Basically the structure is as same as CarouselModule, with less or more parameters and action types.
    if (isRetargetModule(message)) {
      const uriParameters = filterUriParameters(message.parameters);
      message.data.contents.forEach((cardMessage, index) => {
        const finalFooterContents = cardMessage.contents.footer.contents.map((content) => {
          if (content.action.type === 'uri' || content.action.type === 'customUri') {
            const parameter = uriParameters.find(
              (parameter) =>
                (isStandardParameter(parameter) && parameter.key === content.key) ||
                (isCustomParameter(parameter) && parameter.mappingKey === content.key),
            );
            if (parameter) {
              content.action.uri = composeKey(parameter.key);
              content.action.type = 'uri';
            }
          }
          return content;
        });
        message.data.contents[index].contents.footer.contents = finalFooterContents;

        const customProductHeroParameters = message.parameters.filter(
          (parameter) =>
            isProductLinkParameter(parameter) || isProductImageLinkParameter(parameter),
        );
        message.data.contents = message.data.contents.map((card) => {
          const { hero } = card.contents;
          const heroAction = hero?.action;

          if (heroAction) {
            const updateBodyActions = (updatedAction: LineMessageAction) => {
              card.contents.body.contents = card.contents.body.contents.map((content) => ({
                ...content,
                action: updatedAction,
              }));
            };

            switch (heroAction.type) {
              case 'customUri': {
                const productLinkHeroParameter = message.parameters.find(
                  (parameter) =>
                    isCustomParameter(parameter) && parameter.mappingKey === heroAction.key,
                );
                if (productLinkHeroParameter) {
                  heroAction.uri = composeKey(productLinkHeroParameter.key);
                  heroAction.type = 'uri';
                  updateBodyActions(heroAction);
                }
                break;
              }
              case 'none':
                card.contents.hero = omit(hero, ['action']);
                card.contents.body.contents = card.contents.body.contents.map((content) =>
                  omit(content, ['action']),
                );
                break;
            }
          }

          const mappedProductHero = customProductHeroParameters.find(
            (parameter) =>
              isCustomParameter(parameter) && parameter.mappingKey === card.contents.hero?.key,
          );
          if (mappedProductHero?.key && card.contents.hero?.key) {
            card.contents.hero.url = composeKey(mappedProductHero.key);
            return card;
          } else {
            return card?.contents.hero?.url === ''
              ? { ...card, contents: omit(card.contents, ['hero']) }
              : card;
          }
        });
        message.data.contents[index].contents = replaceCardTextWithMappingKey(
          message.data.contents[index].contents,
          message.parameters,
        );
      });
      return message;
    }
    if (isCarouselModule(message)) {
      const uriParameters = filterUriParameters(message.parameters);
      message.data.contents.forEach((cardMessage, index) => {
        const finalFooterContents = cardMessage.contents.footer.contents.map((content) => {
          // Replace Link Parameter and/or Custom URI Parameter uri with <$var:key>
          if (content.action.type === 'uri') {
            const parameter = uriParameters.find(
              (parameter) =>
                (isStandardParameter(parameter) && parameter.key === content.key) ||
                (isCustomParameter(parameter) && parameter.mappingKey === content.key),
            );
            if (parameter) {
              content.action.uri = composeKey(parameter.key);
            }
          }
          return content;
        });
        message.data.contents[index].contents.footer.contents = finalFooterContents;

        const customHeroParameters = message.parameters.filter(
          // Make compatible with the custom hero and custom image parameter.
          (parameter) => isCustomHeroParameter(parameter) || isCustomImageParameter(parameter),
        );
        message.data.contents = message.data.contents.map((card) => {
          // Clone the hero action to the body action if action type is valid.
          const { hero } = card.contents;
          const heroAction = hero?.action;

          if (heroAction) {
            const updateBodyActions = (updatedAction: LineMessageAction) => {
              card.contents.body.contents = card.contents.body.contents.map((content) => ({
                ...content,
                action: updatedAction,
              }));
            };

            switch (heroAction.type) {
              case 'message':
                updateBodyActions(heroAction);
                break;

              case 'uri': {
                const heroActionParameter = message.parameters.find(
                  (parameter) => isStandardParameter(parameter) && parameter.key === heroAction.uri,
                );
                if (heroActionParameter) {
                  heroAction.uri = composeKey(heroActionParameter.key);
                  updateBodyActions(heroAction);
                }
                break;
              }

              case 'customUri': {
                const customUriHeroParameter = message.parameters.find(
                  (parameter) =>
                    isCustomParameter(parameter) && parameter.mappingKey === heroAction.key,
                );
                if (customUriHeroParameter) {
                  heroAction.uri = composeKey(customUriHeroParameter.key);
                  heroAction.type = 'uri';
                  updateBodyActions(heroAction);
                }
                break;
              }
              case 'none':
                card.contents.hero = omit(hero, ['action']);
                card.contents.body.contents = card.contents.body.contents.map((content) =>
                  omit(content, ['action']),
                );
                break;
            }
          }

          // Replace hero image url with <$var:key>
          const mappedCustomHero = customHeroParameters.find(
            (parameter) =>
              isCustomParameter(parameter) && parameter.mappingKey === card.contents.hero?.key,
          );
          if (mappedCustomHero?.key && card.contents.hero?.key) {
            // Replace hero image urls with mapped <$var:key>
            card.contents.hero.url = composeKey(mappedCustomHero.key);
            return card;
          } else {
            // Sanitize hero image if empty
            return card?.contents.hero?.url === ''
              ? { ...card, contents: omit(card.contents, ['hero']) }
              : card;
          }
        });

        // Replace the text with <$var:key> for backend to replace with the actual value
        message.data.contents[index].contents = replaceCardTextWithMappingKey(
          message.data.contents[index].contents,
          message.parameters,
        );
      });
      return message;
    }
    return message;
  });

  if (updatedMessages.length > 0 && quick_reply) {
    updatedMessages[updatedMessages.length - 1].quick_reply = quick_reply;
  }

  return updatedMessages;
};
/**
 * This hook returns everything from the react-hook-form's useForm plus an onSubmit method.
 * Typically, it's used to retrieve form values outside the form.
 * Should be used out of the FromProvider
 *
 * @param messages - messages from server, used for default values
 * @example
 * ```tsx
 * function App() {
 *   const methods = useLineMessageEditorForm([]);
 *   const handleSubmit = async () => {
 *     const updatedMessages = await methods.onSubmit();
 *     // process the message data
 *   }
 *
 *   return (
 *    <>
 *     <FormProvider {...methods} >
 *       <LineMessageEditor />
 *     </FormProvider>
 *     <button onClick={handleSubmit}>Submit</button>
 *    </>
 *   );
 * }
 * ```
 */
export const useLineMessageEditorForm = ({
  getDefaultValues,
}: {
  getDefaultValues:
    | (() => Promise<LineMessageEditorInput['messages']>)
    | LineMessageEditorInput['messages'];
}) => {
  const { t } = useTranslation();
  const { message } = useMessage();
  const { getTagIdsByNames, getTagNamesByIds } = useTagMetadata();
  const enableTagMigration = useEnableTagMigration();

  const methods = useForm<LineMessageEditorInput>({
    mode: 'onChange',
    resolver: zodResolver(LineMessageEditorSchema),
    defaultValues: async () => {
      try {
        const data =
          typeof getDefaultValues === 'function' ? await getDefaultValues() : getDefaultValues;

        return {
          messages: transformServerMessageToUiFormat(data),
          quick_reply: data.length > 0 ? data[data.length - 1]?.quick_reply : { items: [] },
        };
      } catch (error) {
        logError(error);
        return {
          messages: [],
          quick_reply: { items: [] },
        };
      }
    },
  });

  /**
   * The onTrigger function is used to validate the form before submitting.
   * It checks if the form is valid and if the shareable message has a conflict.
   * If the form is invalid or the shareable message has a conflict, it will return false.
   * Otherwise, it will return true.
   */
  const onTrigger = async () => {
    const messages = methods.getValues('messages');

    if (messages.length === 0) {
      message.warning(t('common.pleaseAddMessageModule'));
      return false;
    }

    const isValid = await methods.trigger();

    if (!isValid) return false;

    const hasShareButtonConflict = shareableMessageValidator(messages).hasShareButtonConflict;

    if (hasShareButtonConflict) {
      message.warning(t('message.shareableConflict.hint'));
      return false;
    }

    return true;
  };

  return {
    ...methods,
    onTrigger,
    onSubmit: (): Promise<LineMessageEditorInput['messages']> => {
      return new Promise((resolve, reject) => {
        methods.handleSubmit(
          (result) => {
            resolve(
              transformUiMessageToServerFormat({
                messages: result.messages,
                quick_reply: result.quick_reply,
                getTagIdsByNames,
                getTagNamesByIds,
                enableTagMigration,
              }),
            );
          },
          (errors) => reject(errors),
        )();
      });
    },
  };
};

/**
 * This custom hook allows you to access the form context. Must be used inside the FormProvider.
 *
 * @example
 * ```tsx
 * function App() {
 *   const methods = useLineMessageEditorForm([]);
 *   const onSubmit = data => console.log(data);
 *
 *   return (
 *     <FormProvider {...methods} >
 *       <form onSubmit={methods.handleSubmit(onSubmit)}>
 *         <NestedInput />
 *         <input type="submit" />
 *       </form>
 *     </FormProvider>
 *   );
 * }
 *
 *  function NestedInput() {
 *   const { register } = useLineMessageEditorFormContext(); // retrieve all hook methods
 *   return <input {...register("test")} />;
 * }
 * ```
 */
export const useLineMessageEditorFormContext = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return useFormContext<LineMessageEditorInput, any, LineMessageEditorInput['messages']>();
};
