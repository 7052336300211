import type {
  CardModule,
  CarouselModule,
  CustomObjectParameter,
  CustomParameter,
  CustomParameterWithLink,
  FlexModule,
  ImageCarouselContentHeroAction,
  ImageCarouselModule,
  ImagemapAction,
  ImagemapActionUri,
  ImagemapCarouselModule,
  ImagemapContent,
  ImagemapContentBox,
  ImagemapContentImage,
  ImagemapFlexModule,
  ImagemapModule,
  ImageModule,
  Message,
  NewsModule,
  ObjectParameter,
  Parameter,
  ParameterData,
  PrizeModule,
  RetargetModule,
  SmsModule,
  StandardParameter,
  StandardParameterDataWithSharelink,
  TextModule,
  UriAction,
  VideoModule,
} from 'shared/components/Editor/LineMessageEditor/types';

import {
  LineEditorMessageModuleTypeEnum,
  LineEditorParameterDataFunctionEnum,
  LineEditorParameterTypeEnum,
} from 'shared/models/editor/lineEditor';

export const isCustomParameterWithSharelink = (
  data: ParameterData,
): data is StandardParameterDataWithSharelink => {
  return data.function === LineEditorParameterDataFunctionEnum.Sharelink;
};

export const isCustomParameterWithLink = (data: ParameterData): data is CustomParameterWithLink => {
  return data.function === LineEditorParameterDataFunctionEnum.Link;
};

export const isStandardParameter = (parameter: Parameter): parameter is StandardParameter => {
  return parameter.type === LineEditorParameterTypeEnum.Standard;
};

export const isCustomParameter = (parameter: Parameter): parameter is CustomParameter => {
  return parameter.type === LineEditorParameterTypeEnum.CustomString;
};

export const isCustomObjectParameter = (
  parameter: Parameter,
): parameter is CustomObjectParameter => {
  return parameter.type === LineEditorParameterTypeEnum.CustomObject;
};

export const isObjectParameter = (parameter: Parameter): parameter is ObjectParameter => {
  return parameter.type === LineEditorParameterTypeEnum.Object;
};

export const isCustomImageParameter = (parameter: Parameter): parameter is CustomParameter => {
  return (
    parameter.type === LineEditorParameterTypeEnum.CustomString &&
    parameter.mappingKey.startsWith('customImage')
  );
};

export const isCustomHeroParameter = (parameter: Parameter): parameter is CustomParameter => {
  return (
    parameter.type === LineEditorParameterTypeEnum.CustomString &&
    parameter.mappingKey.startsWith('customHero')
  );
};

export const isProductLinkParameter = (parameter: Parameter): parameter is CustomParameter => {
  return (
    parameter.type === LineEditorParameterTypeEnum.CustomString &&
    parameter.mappingKey.startsWith('productLink')
  );
};

export const isProductImageLinkParameter = (parameter: Parameter): parameter is CustomParameter => {
  return (
    parameter.type === LineEditorParameterTypeEnum.CustomString &&
    parameter.mappingKey.startsWith('productImageLink')
  );
};

export const isCustomTextParameter = (parameter: Parameter): parameter is CustomParameter => {
  return (
    parameter.type === LineEditorParameterTypeEnum.CustomString &&
    parameter.mappingKey.startsWith('customText')
  );
};

export const isCustomLinkParameter = (parameter: Parameter): parameter is CustomParameter => {
  return (
    parameter.type === LineEditorParameterTypeEnum.Standard && parameter.data.function === 'link'
  );
};

export const isCustomUriParameter = (parameter: Parameter): parameter is CustomParameter => {
  return (
    parameter.type === LineEditorParameterTypeEnum.CustomString &&
    parameter.mappingKey.startsWith('customUri')
  );
};

export const isUriAction = (action: ImageCarouselContentHeroAction): action is UriAction => {
  return action.type === 'uri';
};

export const isCustomUriAction = (action: ImageCarouselContentHeroAction): action is UriAction => {
  return 'key' in action;
};

export const isImagemapUriAction = (action: ImagemapAction): action is ImagemapActionUri => {
  return action.type === 'uri';
};

export const isImagemapCarouselUriAction = (
  action: ImageCarouselContentHeroAction,
): action is UriAction => {
  return action.type === 'uri';
};

export const isImagemapCarouselCustomUriAction = (
  action: ImageCarouselContentHeroAction,
): action is UriAction => {
  return action.type === 'customUri';
};

export const isImagemapContentImage = (
  content: ImagemapContent,
): content is ImagemapContentImage => {
  return content.type === 'image';
};

export const isImagemapContentBox = (content: ImagemapContent): content is ImagemapContentBox => {
  return content.type === 'box';
};

export const isSmsModule = (message: Message): message is SmsModule => {
  return message.module_id === LineEditorMessageModuleTypeEnum.Sms;
};

export const isFlexModule = (message: Message): message is FlexModule => {
  return message.module_id === LineEditorMessageModuleTypeEnum.Flex;
};

export const isTextModule = (message: Message): message is TextModule => {
  return message.module_id === LineEditorMessageModuleTypeEnum.Text;
};

export const isImageModule = (message: Message): message is ImageModule => {
  return message.module_id === LineEditorMessageModuleTypeEnum.Image;
};

export const isImageCarouselModule = (message: Message): message is ImageCarouselModule => {
  return message.module_id === LineEditorMessageModuleTypeEnum.ImageCarousel;
};

export const isVideoModule = (message: Message): message is VideoModule => {
  return message.module_id === LineEditorMessageModuleTypeEnum.Video;
};

export const isImagemapModule = (message: Message): message is ImagemapModule => {
  return (
    message.module_id === LineEditorMessageModuleTypeEnum.Imagemap ||
    message.module_id === LineEditorMessageModuleTypeEnum.ImagemapFlex
  );
};

export const isImagemapFlexModule = (message: Message): message is ImagemapFlexModule => {
  return message.module_id === LineEditorMessageModuleTypeEnum.ImagemapFlex;
};

export const isImagemapCarouselModule = (message: Message): message is ImagemapCarouselModule => {
  return message.module_id === LineEditorMessageModuleTypeEnum.ImagemapCarousel;
};

export const isCardModule = (message: Message): message is CardModule => {
  return message.module_id === LineEditorMessageModuleTypeEnum.Card;
};

export const isCarouselModule = (message: Message): message is CarouselModule => {
  return message.module_id === LineEditorMessageModuleTypeEnum.Carousel;
};

export const isRetargetModule = (message: Message): message is RetargetModule => {
  return message.module_id === LineEditorMessageModuleTypeEnum.Retarget;
};

export const isPrizeModule = (message: Message): message is PrizeModule => {
  return message.module_id === LineEditorMessageModuleTypeEnum.Prize;
};

export const isNewsModule = (message: Message): message is NewsModule => {
  return message.module_id === LineEditorMessageModuleTypeEnum.News;
};

export const hasRetargetModule = (messages: Array<Message>) => {
  return messages.some((message) => message.module_id === LineEditorMessageModuleTypeEnum.Retarget);
};
