/* eslint-disable react-refresh/only-export-components */
import { Component } from 'react';
import { height, maxHeight, maxWidth, space, width } from 'styled-system';

import type { RefObject } from 'react';
import type {
  HeightProps,
  MaxHeightProps,
  MaxWidthProps,
  SpaceProps,
  WidthProps,
} from 'styled-system';

import { styled } from 'shared/utils/styled';
import { shouldNotForwardSystemProps } from 'shared/utils/styled/styledSystem';

const Root = styled.div.withConfig({
  shouldForwardProp: shouldNotForwardSystemProps(),
})`
  position: relative;
  background-color: ${({ theme }) => theme.colors.white001};
  border-radius: 10px;
  max-height: 540px;
  width: 374px;
  box-sizing: border-box;
  overflow-y: auto;
  box-shadow: 0 1px 5px 0 #d5dae0;
  ${width};
  ${maxWidth};
  ${height};
  ${maxHeight};
  ${space};
`;

const Content = styled.div`
  padding: 16px 27px 16px 16px;
`;

const Title = styled.div`
  min-width: 177px;
  min-height: 32px;
  margin: 0;
  padding: 12px 16px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  border-bottom: 1px solid #e8e8e8;
`;

interface Props extends WidthProps, HeightProps, MaxWidthProps, MaxHeightProps, SpaceProps {
  innerRef?: RefObject<HTMLDivElement>;
  title?: string;
  children: React.ReactNode;
}

export class PopupContent extends Component<Props> {
  render() {
    return (
      <Root>
        {this.props.title ? <Title>{this.props.title}</Title> : null}
        <Content {...this.props} ref={this.props.innerRef}>
          {this.props.children}
        </Content>
      </Root>
    );
  }
}
