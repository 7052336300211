import { ASSET_CDN_ORIGIN } from 'env';

import type { EditorType } from 'lib/validator/helper/validatorHelpers';

export const MAX_SIZE = 1024 * 1024;
export const MAX_IMAGE_DIMENSIONS_VALUE = 4096;
export const MAX_IMAGE_SIZE = '4096px x 4096px';
export const APNG_FILE_LIMIT = MAX_SIZE * 0.3;
export const SHOULD_CHECK_APNG_EDITOR_TYPES: Array<EditorType> = [
  'ImageMapEditor',
  'ImageMapCarouselEditor',
  'CardEditor',
  'CarrouselEditor',
  'ImageCarouselEditor',
];
export const MAX_APNG_FILE_COUNT = 10;
export const PARAM_PLACEHOLDER = {
  image: `${ASSET_CDN_ORIGIN}/maac/open_api/image-placeholder-new.png?alt=media`,
  link: 'https://example.com',
  text: 'test param text',
};

export const DEFAULT_MAPPING_KEY = {
  customImage: 'customImage',
  customHero: 'customHero',
  customText: 'customText',
  customUri: 'customUri',
  standardLink: 'standardLink',
  messageButton: 'messageButton',
  shareButton: 'shareButton',
} as const;
export type DefaultMappingKey = keyof typeof DEFAULT_MAPPING_KEY;

export const PARAM_RICH_TEXT_FIELD_NAME_INDEX = {
  CardTitle: 0,
  CardDescription: 1,
} as const;
export type ParamRichTextFieldName = keyof typeof PARAM_RICH_TEXT_FIELD_NAME_INDEX;
export type ParamRichTextFieldNamePrefixed = `${ParamRichTextFieldName}${string}`;

export const RICH_EDITOR_ID_NOT_FOUND = -1;

export const IMAGEMAP_CAROUSEL_UI_IMAGE_WIDTH = 272;

export const IMAGE_MAP_UI_IMAGE_WIDTH = 349;
