import { memo } from 'react';
import { useCountUp } from 'react-countup';

import type { ReactElement, ReactNode } from 'react';
import type { CounterProps } from 'react-countup';

import { Text } from 'components';
import { LoadingOutlined } from 'icons/LoadingOutlined';
import { theme } from 'theme';

const BACKEND_CACHE_DATA_EMPTY = -1;

export interface InfoCardProps {
  mainText: string;
  memoText?: string;
  format?: string;
  counts: number;
  icon?: ReactElement;
  bgColor: string;
  color: string;
  countsOption?: CounterProps;
  prefix?: string;
  suffix?: string;
  toFixed?: number;
  mainTextTooltip?: ReactNode;
  stringifyCounts?: string;
  customMemoTextLeft?: number;
}

interface MultiInfoCardProps extends InfoCardProps {
  counts2: number;
  counts2Option?: CounterProps;
  count1String?: string;
  count2String?: string;
}

export const InfoCard = memo(function InfoCard({
  mainText,
  memoText,
  counts,
  icon,
  color,
  bgColor,
  prefix = '',
  suffix = '',
  toFixed,
  mainTextTooltip,
  stringifyCounts,
  customMemoTextLeft,
}: InfoCardProps) {
  return (
    <>
      <Text
        position="absolute"
        top={19}
        left={20}
        color={theme.colors.neutral009}
        fontSize={13}
        height={13}
        lineHeight="13px"
        sentenceCase={true}
      >
        {mainText} {mainTextTooltip}
      </Text>
      {memoText ? (
        <Text
          position="absolute"
          top={20}
          left={customMemoTextLeft || 90}
          color={theme.colors.neutral007}
          height={13}
          lineHeight="13px"
          fontSize={13}
        >
          {memoText}
        </Text>
      ) : null}
      <Text
        position="absolute"
        bottom={16}
        left={20}
        color={theme.colors.neutral010}
        height={20}
        lineHeight="20px"
        fontSize={20}
      >
        {/* {<CountUp counts={counts} />}  因為值變換不會reinitialize所以先拿掉 */}
        {prefix}
        {/* 可傳自己處理好的字串進來 */}
        {stringifyCounts ? stringifyCounts : null}
        {/* 或是傳數字進來這邊會幫你處理基本的 */}
        {!stringifyCounts ? (
          <>
            {counts === BACKEND_CACHE_DATA_EMPTY ? (
              <LoadingOutlined />
            ) : isNaN(counts) ? (
              '-'
            ) : (
              counts.toLocaleString(undefined, {
                minimumFractionDigits: toFixed,
                maximumFractionDigits: toFixed,
              })
            )}
          </>
        ) : null}
        {suffix}
      </Text>
      <div
        style={{
          position: 'absolute',
          top: 16,
          right: 16,
          display: 'flex',
          backgroundColor: bgColor,
          color: color,
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {icon}
      </div>
    </>
  );
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const stringFormat = (input: string, ...others: Array<any>) =>
  input.replace(/{(\d+)}/g, (match, number) =>
    typeof others[number] !== 'undefined' ? others[number] : match,
  );

export const MultiInfoCard = memo(function MultiInfoCard({
  mainText,
  memoText,
  format,
  counts,
  counts2,
  icon,
  color,
  bgColor,
  mainTextTooltip,
  count1String,
  count2String,
  counts2Option,
}: MultiInfoCardProps) {
  // 先不用 CountUp，原作者 issue 都不修。這次遇到：已經開始跑動畫時，再次傳新的值進來無法改變現狀
  // const { countUp } = useCountUp({
  //   start: 0,
  //   end: counts,
  //   delay: 0.5,
  //   duration: 2,
  //   separator: ',',
  //   ...countsOption,
  // });

  // const { countUp: countUp2 } = useCountUp({
  //   start: 0,
  //   end: counts2 ? counts2 : 0,
  //   delay: 0.5,
  //   duration: 2,
  //   separator: ',',
  //   ...counts2Option,
  // });

  const layoutCounts2 = Number.isFinite(counts2)
    ? counts2.toFixed(counts2Option?.decimals ?? 1)
    : '-';

  return (
    <>
      <Text
        position="absolute"
        top={19}
        left={20}
        color={theme.colors.neutral009}
        fontSize={13}
        height={13}
        lineHeight="13px"
      >
        {mainText} {mainTextTooltip}
      </Text>
      {memoText ? (
        <Text
          position="absolute"
          top={20}
          left={90}
          color={theme.colors.neutral007}
          height={13}
          lineHeight="13px"
          fontSize={13}
        >
          {memoText}
        </Text>
      ) : null}
      <Text
        position="absolute"
        bottom={16}
        left={20}
        color={theme.colors.neutral010}
        height={20}
        lineHeight="20px"
        fontSize={20}
      >
        {count1String && count2String ? (
          <>
            {count1String} {count2String}
          </>
        ) : format ? (
          <>
            {stringFormat(
              format,
              counts !== null && counts !== undefined ? counts.toLocaleString() : '-',
              layoutCounts2,
            )}
          </>
        ) : (
          <>
            {count1String
              ? count1String
              : counts !== null && counts !== undefined
                ? counts.toLocaleString()
                : '-'}
            {count2String ? count2String : counts2 ? `（${layoutCounts2}）` : null}
          </>
        )}
      </Text>
      <div
        style={{
          position: 'absolute',
          top: 16,
          right: 16,
          backgroundColor: bgColor,
          color: color,
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {icon}
      </div>
    </>
  );
});

export const CountUp = memo(function CountUp({ counts }: { counts: number }) {
  const { countUp } = useCountUp({
    start: 0,
    end: counts,
    delay: 0.5,
    duration: 2,
    separator: ',',
    redraw: true,
  });
  return <>{countUp.toLocaleString()}</>;
});
