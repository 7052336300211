import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { SubText } from 'components/Typography/Text';
import { LoadingOutlined } from 'icons/LoadingOutlined';
import { styled } from 'shared/utils/styled';
import { isOpenCountLaunched } from 'utils/date/isOpenCountLaunched';

interface TableCTRColumnProps {
  clicks: number | null | undefined;
  count: number;
  isRenderReport?: boolean;
  isSent: boolean;
}
interface OpenCountProps {
  sentCount: number | null | undefined;
  openCount: number;
  isRenderReport?: boolean;
  isSent: boolean;
  scheduledTime: string;
}

const StatsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Stats = memo(function Stats({ title, subtitle }: { title: string; subtitle: string }) {
  return (
    <StatsWrapper>
      <strong>{title}</strong>
      <SubText>{subtitle}</SubText>
    </StatsWrapper>
  );
});

const ClickThroughRate = memo(function ClickThroughRate({
  percents,
  clicks,
  count,
}: {
  percents: string;
  clicks: number;
  count?: number;
}) {
  return <Stats title={count === 0 ? '—' : `${percents}%`} subtitle={clicks.toLocaleString()} />;
});

export const TableCTRColumn = memo(function TableCTRColumn({
  clicks,
  count,
  isRenderReport = true,
  isSent,
}: TableCTRColumnProps) {
  const hasClicks = useMemo(
    () => clicks !== undefined && clicks !== null && count > 0,
    [clicks, count],
  );
  if (isSent && !hasClicks) {
    return <ClickThroughRate clicks={clicks ?? 0} percents="0" count={0} />;
  } else if (!isRenderReport || !hasClicks || !isSent) {
    return <strong>—</strong>;
  } else if (clicks === -1) {
    return <LoadingOutlined />;
  } else
    return (
      <ClickThroughRate
        clicks={Number(clicks)}
        percents={((Number(clicks) / count) * 100).toFixed(1)}
        count={count}
      />
    );
});

const OpenCount = memo(function OpenCount({
  percents,
  openCount,
}: {
  percents: string;
  openCount: number;
}) {
  return <Stats title={`${percents}%`} subtitle={openCount.toLocaleString()} />;
});

export const TableOpenCountColumn = memo(function TableOpenCountColumn({
  sentCount,
  openCount,
  isRenderReport = true,
  isSent,
  scheduledTime,
}: OpenCountProps) {
  const { t } = useTranslation();
  const hasOpenCount = useMemo(
    () => sentCount !== undefined && sentCount !== null && openCount > 0,
    [sentCount, openCount],
  );
  if (!isOpenCountLaunched(scheduledTime)) {
    return <Stats title="—" subtitle={t('common.notTracked')}></Stats>;
  } else if (isSent && !hasOpenCount) {
    return <OpenCount openCount={openCount} percents="0" />;
  } else if (!isRenderReport || !hasOpenCount || !isSent) {
    return <strong>—</strong>;
  } else if (sentCount === -1) {
    return <LoadingOutlined />;
  }
  return (
    <OpenCount
      openCount={openCount}
      percents={((openCount / Number(sentCount)) * 100).toFixed(1)}
    />
  );
});
