import type {
  ObjectParameter,
  StandardParameter,
} from 'shared/components/Editor/LineMessageEditor/types';

import retargetCardHero from 'static/retarget_card_hero.png';
import retargetCardPreviewImg from 'static/retarget_card_hero_preview.png';
import retargetCardPreviewInfoImg from 'static/retarget_card_preview_info.png';

export const MAX_TEXT_MODULE_TEXT_LENGTH = 1600;

export const MAX_SIZE = 1024 * 1024;
export const MAX_IMAGE_DIMENSIONS_VALUE = 4096;
export const MAX_IMAGE_SIZE = '4096px x 4096px';
export const APNG_FILE_LIMIT = MAX_SIZE * 0.3;
export const MAX_APNG_FILE_COUNT = 10;
export const MAX_BUTTON_LABEL_LENGTH = 40;
export const PARAM_PLACEHOLDER = {
  image:
    'https://firebasestorage.googleapis.com/v0/b/cdn.maac.app/o/maac%2Fopen_api%2Fimage-placeholder-new.png?alt=media&token=dc77c7c8-13a3-4226-82d3-2b41f72f20ef',
  link: 'https://example.com',
  text: 'test param text',
};

export const RETARGET_PLACEHOLDER = {
  hero: retargetCardHero,
  preview: retargetCardPreviewImg,
  previewInfo: retargetCardPreviewInfoImg,
};

export const UPLOAD_FILE_FUNCTION_NAME = 'lineMessageEditor';

export const defaultUriParameter: StandardParameter = {
  key: '',
  type: 'standard',
  data: {
    function: 'link',
    open_external_browser: false,
    url: '',
    isUtmTracked: false,
    utm_campaign: '',
    utm_source: '',
    utm_medium: '',
    utm_content: '',
    tag_list: [],
    tags: [],
  },
};

export const defaultRetargetCardParameter: ObjectParameter = {
  key: 'retarget_cards',
  type: 'object',
  data: {
    function: 'retarget_cards',
    isUtmTracked: true,
    utm_campaign: '',
    utm_source: '',
    utm_medium: '',
    utm_content: '',
  },
};
