/**
 * The only one environment variables entry.
 * Do not use process.env directly.
 * Use this instead.
 */
/* eslint-disable no-process-env */

import { toBoolean } from '@chatbotgang/etude/pitch-shifter/boolean';
import { toString } from '@chatbotgang/etude/pitch-shifter/string';

import { stripSpaces } from 'utils/string/whiteSpaces';

export const NODE_ENV = process.env.NODE_ENV || 'development';

/** is NODE_ENV development */
export const inDevelopment = NODE_ENV === 'development';
/** is NODE_ENV production */
export const inProduction = NODE_ENV === 'production';
/** is NODE_ENV test */
export const inTest = NODE_ENV === 'test';

export const ENV: 'staging' | 'production' =
  process.env.REACT_APP_ENV === 'production' ? 'production' : 'staging';

export const API_HOST = toString(process.env.REACT_APP_API_HOST);
export const WS_HOST = toString(process.env.REACT_APP_WS_HOST);
export const ARDITO_API_HOST = toString(process.env.REACT_APP_ARDITO_API_HOST);
export const RECAPTCHA_V2_SITE_KEY = toString(process.env.REACT_APP_RECAPTCHA_V2_SITE_KEY);

export const USERFLOW_TOKEN = toString(process.env.REACT_APP_USERFLOW_TOKEN);
export const USERFLOW_USER_ID_PREFIX = toString(process.env.REACT_APP_USERFLOW_USER_ID_PREFIX);
export const USERFLOW_ATTRIBUTE_PREFIX = toString(process.env.REACT_APP_USERFLOW_ATTRIBUTE_PREFIX);

export const CAAC_URL = toString(process.env.REACT_APP_CAAC_URL);
export const ADMIN_CENTER_HOST = toString(process.env.REACT_APP_ADMIN_CENTER_HOST);
export const SENTRY_ENABLED = toBoolean(process.env.REACT_APP_SENTRY_ENABLED, {
  falsyString: ['false', '0'],
});
export const SENTRY_RELEASE = toString(process.env.REACT_APP_SENTRY_RELEASE);
export const SENTRY_DSN = toString(process.env.REACT_APP_SENTRY_DSN);
export const SENTRY_ALLOW_URLS: Array<string> | undefined = (function getSentryAllowUrls() {
  const urls = (process.env.REACT_APP_SENTRY_ALLOW_URLS ?? '').split('\n').flatMap((url) => {
    const strippedUrl = stripSpaces(url);
    return strippedUrl ? [strippedUrl] : [];
  });
  return urls.length === 0 ? undefined : urls;
})();
export const USER_UPLOAD_CDN_ORIGIN = toString(process.env.REACT_APP_USER_UPLOAD_CDN_ORIGIN);
export const ASSET_CDN_ORIGIN = toString(process.env.REACT_APP_ASSET_CDN_ORIGIN);
export const GA4_ID = toString(process.env.REACT_APP_GA4_ID);

// Activate Mock Service Worker in development
export const MSW_ACTIVE = toBoolean(process.env.REACT_APP_MSW_ACTIVE, {
  falsyString: ['false', '0'],
});

// Experimental flag to activate fixtures, where available
export const MSW_FIXTURES = toBoolean(process.env.REACT_APP_MSW_FIXTURES, {
  falsyString: ['false', '0'],
});

export const USE_BUILT_IN_LOCALES = toBoolean(process.env.REACT_APP_USE_BUILT_IN_LOCALES, {
  falsyString: ['false', '0'],
});

export const FIREBASE_CONTENT_STORAGE_NAME = toString(
  process.env.REACT_APP_FIREBASE_CONTENT_STORAGE_NAME,
);
export const FIREBASE_CONTENT_STORAGE_API_KEY = toString(
  process.env.REACT_APP_FIREBASE_CONTENT_STORAGE_API_KEY,
);
export const FIREBASE_CONTENT_STORAGE_AUTH_DOMAIN = toString(
  process.env.REACT_APP_FIREBASE_CONTENT_STORAGE_AUTH_DOMAIN,
);
export const FIREBASE_CONTENT_STORAGE_DATABASE_URL = toString(
  process.env.REACT_APP_FIREBASE_CONTENT_STORAGE_DATABASE_URL,
);
export const FIREBASE_CONTENT_STORAGE_PROJECT_ID = toString(
  process.env.REACT_APP_FIREBASE_CONTENT_STORAGE_PROJECT_ID,
);
export const FIREBASE_CONTENT_STORAGE_STORAGE_BUCKET = toString(
  process.env.REACT_APP_FIREBASE_CONTENT_STORAGE_STORAGE_BUCKET,
);
export const FIREBASE_CONTENT_STORAGE_MESSAGING_SENDER_ID = toString(
  process.env.REACT_APP_FIREBASE_CONTENT_STORAGE_MESSAGING_SENDER_ID,
);
export const FIREBASE_CONTENT_STORAGE_APP_ID = toString(
  process.env.REACT_APP_FIREBASE_CONTENT_STORAGE_APP_ID,
);
export const FIREBASE_DATA_STORAGE_NAME = toString(
  process.env.REACT_APP_FIREBASE_DATA_STORAGE_NAME,
);
export const FIREBASE_DATA_STORAGE_API_KEY = toString(
  process.env.REACT_APP_FIREBASE_DATA_STORAGE_API_KEY,
);
export const FIREBASE_DATA_STORAGE_AUTH_DOMAIN = toString(
  process.env.REACT_APP_FIREBASE_DATA_STORAGE_AUTH_DOMAIN,
);
export const FIREBASE_DATA_STORAGE_DATABASE_URL = toString(
  process.env.REACT_APP_FIREBASE_DATA_STORAGE_DATABASE_URL,
);
export const FIREBASE_DATA_STORAGE_PROJECT_ID = toString(
  process.env.REACT_APP_FIREBASE_DATA_STORAGE_PROJECT_ID,
);
export const FIREBASE_DATA_STORAGE_STORAGE_BUCKET = toString(
  process.env.REACT_APP_FIREBASE_DATA_STORAGE_STORAGE_BUCKET,
);
export const FIREBASE_DATA_STORAGE_MESSAGING_SENDER_ID = toString(
  process.env.REACT_APP_FIREBASE_DATA_STORAGE_MESSAGING_SENDER_ID,
);
export const FIREBASE_DATA_STORAGE_APP_ID = toString(
  process.env.REACT_APP_FIREBASE_DATA_STORAGE_APP_ID,
);
export const FIREBASE_LOCALE_STORAGE_NAME = toString(
  process.env.REACT_APP_FIREBASE_LOCALE_STORAGE_NAME,
);
export const FIREBASE_LOCALE_STORAGE_API_KEY = toString(
  process.env.REACT_APP_FIREBASE_LOCALE_STORAGE_API_KEY,
);
export const FIREBASE_LOCALE_STORAGE_AUTH_DOMAIN = toString(
  process.env.REACT_APP_FIREBASE_LOCALE_STORAGE_AUTH_DOMAIN,
);
export const FIREBASE_LOCALE_STORAGE_PROJECT_ID = toString(
  process.env.REACT_APP_FIREBASE_LOCALE_STORAGE_PROJECT_ID,
);
export const FIREBASE_LOCALE_STORAGE_STORAGE_BUCKET = toString(
  process.env.REACT_APP_FIREBASE_LOCALE_STORAGE_STORAGE_BUCKET,
);
export const FIREBASE_LOCALE_STORAGE_MESSAGING_SENDER_ID = toString(
  process.env.REACT_APP_FIREBASE_LOCALE_STORAGE_MESSAGING_SENDER_ID,
);
export const FIREBASE_LOCALE_STORAGE_APP_ID = toString(
  process.env.REACT_APP_FIREBASE_LOCALE_STORAGE_APP_ID,
);
export const FIREBASE_TEMP_STORAGE_NAME = toString(
  process.env.REACT_APP_FIREBASE_TEMP_STORAGE_NAME,
);
export const FIREBASE_TEMP_STORAGE_API_KEY = toString(
  process.env.REACT_APP_FIREBASE_TEMP_STORAGE_API_KEY,
);
export const FIREBASE_TEMP_STORAGE_AUTH_DOMAIN = toString(
  process.env.REACT_APP_FIREBASE_TEMP_STORAGE_AUTH_DOMAIN,
);
export const FIREBASE_TEMP_STORAGE_DATABASE_URL = toString(
  process.env.REACT_APP_FIREBASE_TEMP_STORAGE_DATABASE_URL,
);
export const FIREBASE_TEMP_STORAGE_PROJECT_ID = toString(
  process.env.REACT_APP_FIREBASE_TEMP_STORAGE_PROJECT_ID,
);
export const FIREBASE_TEMP_STORAGE_STORAGE_BUCKET = toString(
  process.env.REACT_APP_FIREBASE_TEMP_STORAGE_STORAGE_BUCKET,
);
export const FIREBASE_TEMP_STORAGE_MESSAGING_SENDER_ID = toString(
  process.env.REACT_APP_FIREBASE_TEMP_STORAGE_MESSAGING_SENDER_ID,
);
export const FIREBASE_TEMP_STORAGE_APP_ID = toString(
  process.env.REACT_APP_FIREBASE_TEMP_STORAGE_APP_ID,
);
export const FIREBASE_SHARED_API_KEY = toString(process.env.REACT_APP_FIREBASE_SHARED_API_KEY);
export const FIREBASE_SHARED_AUTH_DOMAIN = toString(
  process.env.REACT_APP_FIREBASE_SHARED_AUTH_DOMAIN,
);
export const FIREBASE_SHARED_PROJECT_ID = toString(
  process.env.REACT_APP_FIREBASE_SHARED_PROJECT_ID,
);
export const FIREBASE_SHARED_APP_ID = toString(process.env.REACT_APP_FIREBASE_SHARED_APP_ID);
export const STATSIG_CLIENT_API_KEY = toString(process.env.REACT_APP_STATSIG_CLIENT_API_KEY);
