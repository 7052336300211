import styled from '@emotion/styled';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import {
  EDITOR_PRODUCT_NAME_TAG_REGEX,
  EDITOR_PRODUCT_PRICE_TAG_REGEX,
} from 'shared/components/Editor/constants';
import { RETARGET_PLACEHOLDER } from 'shared/components/Editor/LineMessageEditor/constants';

import type { CardContent, Parameter } from 'shared/components/Editor/LineMessageEditor/types';

import { i18n } from 'modules/G11n/i18n';
import { getColorByTagType } from 'shared/components/Editor/LineMessageEditor/utils/getColorByTagType';
import {
  isCustomParameterWithLink,
  isStandardParameter,
} from 'shared/components/Editor/LineMessageEditor/utils/helper';
import { Tooltip, TooltipDescription } from 'shared/components/Tooltip';
import { Typography } from 'shared/components/Typography';
import { theme } from 'theme';

const Img = styled.img`
  width: 100%;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
`;

const RetargetCardPreviewContainer = styled.div`
  width: 223px;
  border-radius: 16px;
  background-color: ${theme.colors.white001};
`;

const RetargetCardBodyContainer = styled.div`
  background-color: ${theme.colors.white001};
  border-radius: 0 0 16px 16px;
`;

const RetargetCardBodyContent = styled.div`
  padding: 12px 24px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const FooterContainer = styled.div`
  padding: 8px;
  text-align: center;
`;

const transformText = (text: string) => {
  if (text.match(EDITOR_PRODUCT_NAME_TAG_REGEX)) {
    return (
      <span
        style={{
          color: getColorByTagType('custom'),
        }}
      >
        {i18n.t('common.textsWithColon', {
          textBeforeColon: i18n.t('message.preview.parameter'),
          textAfterColon: i18n.t('message.richEditor.tag.productName'),
        })}
      </span>
    );
  } else if (text.match(EDITOR_PRODUCT_PRICE_TAG_REGEX)) {
    return (
      <span
        style={{
          color: getColorByTagType('custom'),
        }}
      >
        {i18n.t('common.textsWithColon', {
          textBeforeColon: i18n.t('message.preview.parameter'),
          textAfterColon: i18n.t('message.richEditor.tag.productPrice'),
        })}
      </span>
    );
  } else return text;
};

export const LineMessageEditorRetargetModulePreview = ({
  parameters,
  cardContent,
}: {
  parameters: Array<Parameter>;
  cardContent: CardContent;
}) => {
  const { t } = useTranslation();
  const [card, description] = cardContent.body.contents;
  const heroActionType = cardContent.hero?.action?.type;

  const heroActionHint = useMemo(() => {
    switch (heroActionType) {
      case 'customUri':
        return t('message.preview.heroAction.tooltip');
      case 'none':
        return '';
      default:
        return '';
    }
  }, [heroActionType, t]);

  return (
    <RetargetCardPreviewContainer>
      <Tooltip
        trigger="hover"
        title={heroActionHint ? <TooltipDescription>{heroActionHint}</TooltipDescription> : ''}
      >
        <Img src={RETARGET_PLACEHOLDER.preview} />
      </Tooltip>
      <RetargetCardBodyContainer>
        <RetargetCardBodyContent>
          <Tooltip
            trigger="hover"
            title={heroActionHint ? <TooltipDescription>{heroActionHint}</TooltipDescription> : ''}
          >
            <Typography.Body style={{ marginBottom: '8px' }}>
              {transformText(card.text)}
            </Typography.Body>
            <Typography.Body>{transformText(description.text)}</Typography.Body>
          </Tooltip>
        </RetargetCardBodyContent>
        <FooterContainer>
          {cardContent.footer.contents.map((content, index) => {
            return (
              <Tooltip
                key={`action-${index}`}
                trigger="hover"
                title={
                  <TooltipDescription>
                    {(() => {
                      switch (content.action.type) {
                        case 'customUri':
                          return t('message.preview.footerAction.openUnpaidItems.tooltip');
                        case 'message':
                          return `${t('message.send')}: ${content.action.text}`;
                        case 'uri':
                          const uriParameter = parameters.find((parameter) => {
                            return isStandardParameter(parameter) && parameter.key === content.key;
                          });
                          if (
                            uriParameter &&
                            uriParameter.data &&
                            isCustomParameterWithLink(uriParameter.data)
                          )
                            return `${t('common.openUrl')}: ${uriParameter.data.url}`;
                          return '';
                        default:
                          content.action satisfies never;
                          return '';
                      }
                    })()}
                  </TooltipDescription>
                }
              >
                <Typography.Body
                  color={theme.colors.blue006}
                  style={{ cursor: 'pointer', marginBottom: 12 }}
                  onClick={() => {
                    if (content.action.type !== 'uri') return;
                    const uriParameter = parameters.find((parameter) => {
                      return isStandardParameter(parameter) && parameter.key === content.key;
                    });
                    const url =
                      uriParameter && isCustomParameterWithLink(uriParameter.data)
                        ? uriParameter.data.url
                        : '';
                    if (url) window.open(url);
                  }}
                >
                  {content.action.label}
                </Typography.Body>
              </Tooltip>
            );
          })}
        </FooterContainer>
      </RetargetCardBodyContainer>
    </RetargetCardPreviewContainer>
  );
};

export const LineMessageEditorRetargetModulePreviewInfo = () => {
  const { t } = useTranslation();

  return (
    <RetargetCardPreviewContainer>
      <Img src={RETARGET_PLACEHOLDER.previewInfo} />
      <div
        style={{
          padding: '10px 12px',
          display: 'flex',
          alignItems: 'center',
          height: '120px',
        }}
      >
        <Typography.Body>{t('message.editor.retarget.preview.info')}</Typography.Body>
      </div>
    </RetargetCardPreviewContainer>
  );
};
