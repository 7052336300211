import type { AnyFunction } from '@chatbotgang/etude/function/AnyFunction';
import type { DeeplinkMessageMemberValue } from 'modules/Deeplink/types';
import type { PrizeCategory, PrizeRedemptionMode } from 'modules/Prize/types';
import type { LineEditorMessageModuleType } from 'shared/models/editor/lineEditor';
import type { JourneyNodeTriggerType } from 'shared/models/journey/nodeCommon';
import type { IsStringLiteral } from 'type-fest';

/**
 * Sidebar submenu items; these need to be clicked to access actual links
 */
export const SidebarSubmenuIdEnum = {
  Dashboard: 'dashboard',
  Audience: 'audience',
  MessageCenter: 'message-center',
  Applications: 'applications',
  Automation: 'automation',
  Api: 'api',
} as const;

/**
 * Sidebar items; these are the actual links that are accessed by the user and trigger navigation
 */
export const SidebarItemIdEnum = {
  DashboardLineOverview: 'dashboard-line-overview',
  DashboardSmsOverview: 'dashboard-sms-overview',
  DashboardAcquisition: 'dashboard-acquisition',
  DashboardEngagement: 'dashboard-engagement',
  DashboardMaacAi: 'dashboard-maac-ai',
  DashboardConversion: 'dashboard-conversion',
  Members: 'members',
  Segments: 'segments',
  TagManager: 'tag-manager',
  Broadcast: 'broadcast',
  TemplateLibrary: 'template-library',
  RichMenu: 'rich-menu',
  AutoReply: 'auto-reply',
  Deeplink: 'deeplink',
  Widget: 'widget',
  Tracelink: 'tracelink',
  Prize: 'prize',
  Journey: 'journey',
  Retarget: 'retarget',
  Dpm: 'dpm',
  SmsPlus: 'sms-plus',
  Chat: 'chat',
  AppMarketing: 'app-marketing',
  ApiBroadcast: 'api-broadcast',
  ApiToken: 'api-token',
  Zapier: 'zapier',
  Webhook: 'webhook',
} as const;

export type SidebarItemId = (typeof SidebarItemIdEnum)[keyof typeof SidebarItemIdEnum];

export const SidebarIdEnum = {
  ...SidebarSubmenuIdEnum,
  ...SidebarItemIdEnum,
} as const;

export type SidebarId = (typeof SidebarIdEnum)[keyof typeof SidebarIdEnum];

/**
 * The following selection contains all test IDs consumed by Cypress and sprinkled throughout the app
 */
const accountIds = {
  accountSetupRegister: 'setup-register-action',
  loginFailedAlert: 'login-failed-alert',
  loginUsernameField: 'username-field-login',
  loginPasswordField: 'password-field-login',
  loginSubmitButton: 'login-submit',
  loginOtpCodeField: 'otp-code-field',
  loginOtpCodeSubmit: 'otp-code-submit',
} as const;

const applicationIds = {
  channelMenuDropdown: 'bot-menu-dropdown',
  channelMenuItem: (id: number) => `bot-menu-item-${id}` as const,
  mainContent: (id: SidebarItemId) => `main-content-${id}` as const,
  mainHeaderProfile: 'main-header-profile',
  mainHeaderProfileOrganization: 'main-header-profile-organization',
  memberMessageCollapseSaveButton: 'member-message-collapse-save-button',
  sidebar: (id: SidebarId) => `sidebar-${id}` as const,
} as const;

const appMarketingIds = {
  appMarketingRankings: 'app-marketing-rankings',
} as const;

const autoReplyIds = {
  autoReplyListCreateButton: 'autoreply-create-button',
  autoReplyListArchivedTab: 'autoreply-tabs-archived',
  autoReplyListKeywordTab: 'autoreply-tabs-keyword',
  autoReplyFormFieldName: 'autoreply-name-field',
  autoReplyFormFieldEventTypeSelect: 'autoreply-event-type-select',
  autoReplyFormFieldKeyword: 'autoreply-keyword-field',
  autoReplyFormFieldKeywordScheduleSelect: 'autoreply-keyword-schedule-select',
  autoReplyFormFieldFixedReplyScheduleSelect: 'autoreply-fixed-reply-schedule-select',
  autoReplyFormButtonNext: 'autoreply-create-action-next',
  autoReplyReportPerformanceTitle: 'autoreply-report-message-performance-title',
  autoReplyTriggerTextPrimary: (id: string) => `autoreply-trigger-text-primary-${id}` as const,
  autoReplyTriggerTextSecondary: (id: string) => `autoreply-trigger-text-secondary-${id}` as const,
  autoReplyFollowIcon: (id: string) => `autoreply-follow-icon-${id}` as const,
  autoReplyMenu: (id: string) => `autoreply-menu-${id}` as const,
  autoReplyEditLink: (id: string) => `autoreply-edit-link-${id}` as const,
  autoReplySwitch: (id: string) => `autoreply-switch-${id}` as const,
  autoReplyEnableOrArchiveLink: (id: string) => `autoreply-enable-or-archive-link-${id}` as const,
  autoReplyConfirmArchive: (id: string) => `autoreply-confirm-archive-${id}` as const,
  autoReplyReportLink: (id: string) => `autoreply-report-link-${id}` as const,
  autoReplyDeleteLink: (id: string) => `autoreply-delete-link-${id}` as const,
  autoReplyConfirmDelete: (id: string) => `autoreply-confirm-delete-${id}` as const,
} as const;

const broadcastIds = {
  broadcastFormActionNext: 'broadcast-action-next',
  broadcastFormFieldName: 'broadcast-field-name',
  broadcastFormSaveDraft: 'broadcast-save-as-draft',
  broadcastList: 'broadcast-list',
  broadcastListCreateButton: 'broadcast-create-button',
  broadcastListMenuDelete: 'broadcast-menu-delete',
  broadcastListDeleteModalConfirm: 'broadcast-list-delete-confirm-button',
  broadcastListSmsTab: 'broadcast-sms-button',
  broadcastSmsList: 'broadcast-sms-list',
  broadcastSmsListCreateButton: 'broadcast-sms-create-button',
} as const;

const componentIds = {
  timeRangePickerStart: 'time-range-picker-start',
  timeRangePickerEnd: 'time-range-picker-end',
} as const;

const deeplinkIds = {
  deeplinkEditLink: (id: number) => `deeplink-edit-link-${id}` as const,
  deeplinkCopyLink: (id: number) => `deeplink-copy-link-${id}` as const,
  deeplinkArchiveLink: (id: number) => `deeplink-archive-link-${id}` as const,
  deeplinkMenuButton: (id: number) => `deeplink-menu-button-${id}` as const,
  deeplinkBatchActionEdit: 'deeplink-batch-action-edit',
  deeplinkBatchActionCreate: 'deeplink-batch-action-create',
  deeplinkBatchSelectExport: 'deeplink-batch-select-export',
  deeplinkListCreateButton: 'deeplink-create-button',
  deeplinkCreateConfirmButton: 'deeplink-create-confirm-save',
  deeplinkCreateNextButton: 'deeplink-create-action-next',
  deeplinkCreateMemberToggle: (id: DeeplinkMessageMemberValue) =>
    `deeplink-create-member-message-toggle-${id}` as const,
  deeplinkCreateNameField: 'deeplink-name-field',
} as const;

const dpmIds = {
  dpmListCreateButton: 'dpm-create-button',
  dpmListDeleteButton: 'dpm-delete-button',
  dpmListDeleteModalConfirmButton: 'dpm-delete-modal-confirm-button',
  dpmFormFieldName: 'dpm-name-field',
  dpmFormFieldText: 'dpm-text-field',
  dpmFormFieldNotification: 'dpm-notification-field',
  dpmFormFieldUtmSource: 'dpm-utm-source-field',
  dpmFormFieldUtmMedium: 'dpm-utm-medium-field',
  dpmFormFieldUtmCampaign: 'dpm-utm-campaign-field',
  dpmFormFieldUtmContent: 'dpm-utm-content-field',
  dpmButtonNextStep: 'dpm-create-action-next',
  dpmButtonSaveAsDraft: 'dpm-create-action-save',
} as const;

const journeyIds = {
  journeyEditorTriggerMenuOption: (id: JourneyNodeTriggerType) =>
    `journey-trigger-menu-${id}` as const,
  journeyEditorTriggerTagSelect: 'journey-tag-select',
  journeyEditorSaveButton: 'journey-editor-save-button',
  journeyEditorPublishButton: 'journey-editor-publish-button',
  journeyFormFieldName: 'journey-form-name',
  journeyFormNextButton: 'journey-form-next-button',
  journeyListCreateButton: 'journey-list-create-button',
  journeyListItemDelete: (id: number) => `journey-delete-link-${id}` as const,
  journeyListItemMenu: (id: number) => `journey-item-menu-${id}` as const,
  journeyListItemToggle: (id: number) => `journey-item-toggle-${id}` as const,
  journeyListReportButton: (id: number) => `journey-report-link-${id}` as const,
  journeyListItemDeleteModal: 'journey-list-delete-modal',
  journeyReportCanvasTab: 'journey-canvas-trigger',
} as const;

const lineEditorIds = {
  editorMain: 'line-message-editor',
  editorRichText: 'rich-text-editor',
  editorTemplateDropdown: 'template-dropdown',
  editorTemplateDropdownSave: 'template-dropdown-save-as-template',
  editorTemplateSaveModalNameInput: 'template-save-as-modal-name-input',
  editorTemplateSaveModalSubmit: 'template-save-as-modal-submit',
  editorPrizeRedeemPopover: 'prize-redeem-editor-popover',
  editorModuleSelector: (id: LineEditorMessageModuleType) =>
    `module-selector-${id}-module` as const,
  editorImageEdit: 'line-editor-image-edit',
  editorImageEditIndex: (index: number) => `line-editor-image-edit-${index}` as const,
  editorMessageInput: (index: number) => `line-editor-message-input-${index}` as const,
  /** TODO: improve typing of this ID function */
  editorPreviewFlex: (id: string) => `flex-preview-${id}` as const,
  editorImageEditorPopoverLocal: 'image-editor-popover-local',
  editorImageEditorPopoverLocalIndex: (index: number) =>
    `image-editor-popover-local-${index}` as const,
  editorImageEditorPopover: 'image-editor-popover',
  editorImageEditorPopoverIndex: (index: number) => `image-editor-popover-${index}` as const,
  editorImageEditorPopoverParam: 'image-editor-popover-param',
  editorImageEditorPopoverParamIndex: (index: number) =>
    `image-editor-popover-param-${index}` as const,
  editorImageEditorPopoverParamKey: 'image-editor-popover-param-key',
  editorImageEditorPopoverParamKeyIndex: (index: number) =>
    `image-editor-popover-param-key-${index}` as const,
  editorImageEditorPopoverLocalUploadImageButton: 'image-editor-popover-local-upload-image-button',
  editorImageEditorPopoverLocalUploadImageButtonIndex: (index: number) =>
    `image-editor-popover-local-upload-image-button-${index}` as const,
  editorImageEditorPopoverLocalUploadImageInput: 'image-editor-popover-local-upload-image-input',
  editorImageEditorPopoverLocalUploadImageInputIndex: (index: number) =>
    `image-editor-popover-local-upload-image-input-${index}` as const,
  editorImagePreviewLocalUploadImage: 'image-preview-local-upload-image',
  editorRetargetCardParamRichTextEditor: (index: number) =>
    `retarget-card-title-param-rich-text-editor-${index}` as const,
  editorRetargetCardDescriptionParamRichTextEditor: (index: number) =>
    `retarget-card-description-param-rich-text-editor-${index}` as const,
  editorRetargetCardEditorButtonLabel: (index: number) =>
    `retarget-card-editor-button-label-${index}` as const,
  editorRetargetCardActionButtonMessage: (index: number, retargetIndex: number) =>
    `retarget-card-action-button-message-${index}-${retargetIndex}` as const,
  editorRetargetCardActionButtonUri: (index: number, retargetIndex: number) =>
    `retarget-card-action-button-uri-${index}-${retargetIndex}` as const,
  editorRetargetCardActionButtonMessageInput: (index: number, retargetIndex: number) =>
    `retarget-card-action-button-message-input-${index}-${retargetIndex}` as const,
  editorRetargetCardActionButtonUriInput: (index: number, retargetIndex: number) =>
    `retarget-card-action-button-uri-input-${index}-${retargetIndex}` as const,
  editorRetargetCardActionButtonCustomUri: (index: number, retargetIndex: number) =>
    `retarget-card-action-button-customUri-${index}-${retargetIndex}` as const,
  editorRetargetCardActionButton: (index: number, retargetIndex: number) =>
    `retarget-card-action-button-${index}-${retargetIndex}` as const,
  editorRetargetCardNotificationText: 'retarget-card-notification-text',
  editorRetargetCardFooterButton: 'add-retarget-card-footer-button',
  editorRetargetCardHeroNotificationText: 'retarget-card-hero-notification-text',
  editorRetargetCardHeroActionButtonCustomUri: 'retarget-card-hero-action-button-customUri',
  editorRetargetImageEditorSettingPopover: 'retarget-image-editor-setting-popover',
} as const;

const membersIds = {
  membersListTable: 'member-list-table',
  membersListItemName: 'member-list-item-name',
  membersReport: 'member-data-report',
  membersReportUsername: 'member-data-report-username',
} as const;

const organizationIds = {
  organizationBusinessHoursEditButton: 'business-hours-edit-button',
  organizationBusinessHoursCheckbox: 'business-hours-checkbox',
  organizationBusinessHoursTimeFieldHours: 'business-hours',
  organizationBusinessHoursTimeFieldHour: 'business-hour',
  organizationBusinessHoursTimeFieldStartTime: 'business-hours-start-time',
  organizationBusinessHoursTimeFieldEndTime: 'business-hours-end-time',
  organizationBusinessHoursTimeFieldErrorMessage: 'business-hour-error-message',
  organizationBusinessHoursButtonAddTime: 'add-business-hours-button',
  organizationBusinessHoursButtonDeleteTime: 'delete-business-hour-button',
  organizationBusinessHoursButtonSaveForm: 'save-business-hour-form-button',
} as const;

const prizeIds = {
  prizeUploadCodesFileInput: 'upload-label-file-input',
  prizeListNameField: 'prize-list-name-field',
  prizeListCreateButton: 'prize-list-create-button',
  prizeListEditButton: 'prize-list-edit-button',
  prizeListDropdown: (id: number) => `prize-list-dropdown-${id}` as const,
  prizeListDelete: (id: number) => `prize-list-delete-${id}` as const,
  prizeListDeleteConfirm: 'prize-list-delete-confirm',
  prizeFormNameField: 'prize-name-field',
  prizeFormRedemptionModeSelect: 'redemption-mode-select',
  prizeFormRedemptionMode: (id: PrizeRedemptionMode) => `redemption-mode-${id}` as const,
  prizeFormTypeSelect: 'prize-type-select',
  prizeFormActionSubmit: 'prize-action-submit',
  prizeFormActionNext: 'prize-action-next',
  prizeFormActionConfirm: 'prize-action-confirm',
  prizeFormCategory: (id: PrizeCategory) => `prize-type-${id}` as const,
  prizeFormPointsMemo: 'prize-points-memo',
  prizeFormButtonNameField: (id: string) => `prize-${id}-button-name-field` as const,
  prizeFormButtonTypeSelect: (id: string) => `prize-${id}-button-type-select` as const,
  prizeFormButtonTypeSelectUri: (id: string) => `prize-${id}-button-type-select-uri` as const,
  prizeFormButtonTypeSelectKeyword: (id: string) =>
    `prize-${id}-button-type-select-keyword` as const,
  prizeFormButtonKeywordField: (id: string) => `prize-${id}-button-keyword-field` as const,
  prizeFormButtonUriField: (id: string) => `prize-${id}-button-uri-field` as const,
  prizeFormCodeFixedInput: 'prize-code-fixed-input',
  prizeFormSettingsNotificationInput: 'prize-settings-notification-input',
  prizeFormSettingsSelect: 'prize-settings-prize-select',
  prizeFormUtmSource: 'prize-utm-source',
  prizeFormUtmMedium: 'prize-utm-medium',
  prizeFormUtmCampaign: 'prize-utm-campaign',
  prizeFormMessageEditor: 'prize-message-editor',
  prizeFormMessageEditorModalSubmit: 'prize-message-editor-modal-submit',
  prizeCodeUploadFulfilled: 'prize-code-upload-fulfilled',
} as const;

const retargetIds = {
  retargetList: 'retarget-list',
  retargetListCreateButton: 'retarget-list-create-button',
  retargetCreateNextButton: 'retarget-create-next-button',
  retargetCreateNameField: 'retarget-create-name-field',
  retargetCreateSaveDraftButton: 'retarget-create-save-draft-button',
  retargetListEditButton: (id: number) => `retarget-list-edit-button-${id}` as const,
  retargetListDeleteButton: (id: number) => `retarget-list-delete-button-${id}` as const,
  retargetModalDeleteButton: 'retarget-modal-delete-button',
} as const;

const richMenuIds = {
  richMenuListCreateButton: 'richmenu-list-create-button',
  richMenuFormNameField: 'richmenu-name-input',
  richMenuFormNextButton: 'richmenu-next-button',
  richMenuFormImageUpload: 'richmenu-image-upload-input',
  richMenuFormImage: 'richmenu-image',
  richMenuFormTextButton: 'richmenu-text-button',
  richMenuFormTextInput: 'richmenu-text-input',
  richMenuFormScheduleButton: 'richmenu-schedule-button',
} as const;

const segmentIds = {
  segmentAddFilterGroup: 'segment-add-filter-group',
  segmentAppendNewFilter: 'segment-append-new-filter',
  segmentCreateButton: 'segment-create-button',
  segmentCreateModalCreateButton: 'segment-create-modal-create-button',
  segmentCreateSaveAction: 'segment-create-save-action',
  segmentFilterDoneButton: 'segment-filter-done-button',
  segmentFiltersGroup: 'segment-filters-group',
  segmentFilterGroup: (index: number) => `segment-filter-group-${index}` as const,
  segmentFilterMenuItemGender: 'filter-menu-item-gender',
  segmentFilterMenuItemPhone: 'filter-menu-item-phone',
  segmentFilterMenuItemTag: 'filter-menu-item-tag',
  segmentInitializeFilter: 'segment-initialize-filter',
  segmentListDelete: (id: number) => `segment-list-delete-${id}` as const,
  segmentListDeleteConfirm: 'segment-list-delete-confirm',
  segmentListDropdown: (id: number) => `segment-list-dropdown-${id}` as const,
  segmentListNameField: 'segment-list-name-field',
  segmentNameInput: 'segment-name-input',
  segmentRemoveFilterGroup: 'segment-remove-filter-group',
} as const;

const signupIds = {
  signupLink: 'signup-link',
  signupFirstName: 'signup-first-name',
  signupLastName: 'signup-last-name',
  signupPhone: 'signup-phone',
  signupOrganization: 'signup-organization',
  signupEmail: 'signup-email',
  signupPassword: 'signup-password',
  signupAgreement: 'signup-agreement',
  signupSubmit: 'signup-submit',
  signupVerify1: 'signup-verify-1',
  signupVerifyRetry: 'signup-verify-retry',
  signupVerify2: 'signup-verify-2',
  signupChangeEmail: 'signup-change-email',
  signupChangeEmailSubmit: 'signup-change-email-submit',
} as const;

const templateIds = {
  templateBasicSection: 'template-basic-section',
  templateCardWrapper: 'template-card-wrapper',
  templateNextButton: 'template-next-button',
} as const;

const templateLibraryIds = {
  templateLibraryList: 'template-library-list',
  templateLibraryListCreateButton: 'template-library-create-button',
  templateLibraryListActionDropdown: 'template-library-list-action-dropdown',
  templateLibraryListActionDelete: 'template-library-list-action-delete',
  templateLibraryListDeleteModalConfirm: 'template-library-list-delete-modal-confirm',
  templateLibraryFormFieldName: 'template-library-name',
  templateLibraryFormNextButton: 'template-library-next-step',
  templateLibraryFormCreateButton: 'template-library-create-step',
} as const;

const tagManagerIds = {
  tagManagerList: 'tag-manager-list',
} as const;

const tracelinkIds = {
  tracelinkFormCreateButton: 'tracelink-form-create',
  tracelinkFormAddButton: 'tracelink-form-add',
  tracelinkFormNameInput: (id: number) => `tracelink-name-input-${id}` as const,
  tracelinkFormUrlInput: (id: number) => `tracelink-url-input-${id}` as const,
  tracelinkFormTrackCheckbox: (id: number) => `tracelink-track-checkbox-${id}` as const,
  tracelinkFormUtmSourceInput: (id: number) => `tracelink-utm-source-input-${id}` as const,
  tracelinkFormUtmMediumInput: (id: number) => `tracelink-utm-medium-input-${id}` as const,
  tracelinkFormUtmCampaignInput: (id: number) => `tracelink-utm-campaign-input-${id}` as const,
  tracelinkFormUtmContentInput: (id: number) => `tracelink-utm-content-input-${id}` as const,
  tracelinkFormSaveButton: 'tracelink-save',
  tracelinkFormOnCreatedButton: 'tracelink-created-button',
  tracelinkListArchiveButton: (id: number) => `tracelink-archive-${id}` as const,
  tracelinkListCreateButton: 'tracelink-list-create',
} as const;

/**
 * All test IDs consumed by Cypress
 */
export const testIds = {
  ...accountIds,
  ...applicationIds,
  ...appMarketingIds,
  ...autoReplyIds,
  ...broadcastIds,
  ...componentIds,
  ...deeplinkIds,
  ...dpmIds,
  ...journeyIds,
  ...lineEditorIds,
  ...membersIds,
  ...organizationIds,
  ...prizeIds,
  ...retargetIds,
  ...richMenuIds,
  ...segmentIds,
  ...signupIds,
  ...tagManagerIds,
  ...templateIds,
  ...templateLibraryIds,
  ...tracelinkIds,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ExtractTestIdValues<T extends Record<string, string | ((...arg: any) => string)>> = {
  [K in keyof T]: T[K] extends AnyFunction ? ReturnType<T[K]> : T[K];
}[keyof T];

// Ensure only string literals are returned by the test IDs
// If you forget an "as const" in the definitions this will throw errors everywhere
export type TestIdsValue =
  IsStringLiteral<ExtractTestIdValues<typeof testIds>> extends true
    ? ExtractTestIdValues<typeof testIds>
    : never;
