const nullableArr: Array<null | undefined> = [null, undefined];

/**
 * Check if the value is nullable nor not.
 *
 * @param value The value to check.
 * @returns Return true if the value is nullable.
 */
export function isNullable<T = unknown>(value: T): value is T & (null | undefined) {
  return (nullableArr as Array<unknown>).includes(value);
}
