import { toBoolean } from '@chatbotgang/etude/pitch-shifter/boolean';
import { toString } from '@chatbotgang/etude/pitch-shifter/string';

import type { IValidateResponse } from 'components/UploadPrizeCodes/types';
import type { ValidatablePrizeRedemptionMode } from 'modules/Prize/types';
import type { DeferFn } from 'react-async';

import { AuthAxios as axios } from 'lib/axios';
import { PrizeRedemptionModeEnum } from 'modules/Prize/types';

const redemptionModeMapping: Record<ValidatablePrizeRedemptionMode, string> = {
  [PrizeRedemptionModeEnum.ChangedCode]: 'code',
  [PrizeRedemptionModeEnum.UniqueCode]: 'unique_code',
  [PrizeRedemptionModeEnum.UniqueUrl]: 'unique_url',
  [PrizeRedemptionModeEnum.UniqueUrlWithPassword]: 'unique_url_with_password',
};

export const validate: DeferFn<IValidateResponse> = async ([
  bot,
  file,
  isUniqueCode,
  redemptionMode,
]) => {
  const postData = new FormData();
  postData.append('file', file);
  postData.append('bot_id', bot);
  postData.append('is_unique_code', toString(toBoolean(isUniqueCode)));
  postData.append(
    'column_type',
    redemptionModeMapping[redemptionMode as ValidatablePrizeRedemptionMode],
  );
  const resp = await axios.post<IValidateResponse>('/prize/v1/fileupload/', postData);
  return resp.data;
};
