import createDOMPurify from 'dompurify';

import type { JSX } from 'react';

const purify = createDOMPurify(window);

export const notificationUrlParser = (inputString: string): Array<JSX.Element | string> => {
  const MK_LINK_REGEX = /[[]{1}([^\]]+)[\]]{1}[(]{1}([^)“]+)(“(.+)“)?[)]{1}/g;
  const A_TAG_REGEX = /(<\s*a[^>]*>.*?<\s*\/\s*a>)/g;

  return inputString
    .replace(MK_LINK_REGEX, (_match, p1, p2) => {
      const decodedUrl = decodeURIComponent(p2);
      return `<a href="${decodedUrl}" title="${p1}">${p1}</a>`;
    })
    .split(A_TAG_REGEX)
    .map((d) => {
      if (d.match(A_TAG_REGEX) !== null) {
        // eslint-disable-next-line react/jsx-key -- Old code, best left alone
        return <span dangerouslySetInnerHTML={{ __html: purify.sanitize(d) }} />;
      } else return d;
    });
};

export function parseBreakLine(
  text: Array<JSX.Element | string>,
): Array<JSX.Element | Array<string | JSX.Element>> {
  const LINE_BREAK_REGEX = /(<br\/>)/g;

  return text.map((d) => {
    if (typeof d === 'string') {
      return d.split(LINE_BREAK_REGEX).map((line, index) => {
        return line.match(LINE_BREAK_REGEX) ? <br key={'br_key_' + index} /> : line;
      });
    } else return d;
  });
}
