import { toArray } from 'stringz';

/**
 * All unicode of all spaces
 * See the whole list in: https://en.wikipedia.org/wiki/Whitespace_character
 * We use this list of unicode to compare with the provided character array which derives from the provided string
 * because JavaScript string functions (replace, split...etc) are not emojis safe.
 * See https://mathiasbynens.be/notes/javascript-unicode
 */
const spacesUnicode = [
  '\u000d',
  '\u000a',
  '\u0009',
  '\u000c',
  '\u000b',
  '\u0020',
  '\u0085',
  '\u00a0',
  '\u1680',
  '\u180e',
  '\u2000',
  '\u2001',
  '\u2002',
  '\u2003',
  '\u2004',
  '\u2005',
  '\u2006',
  '\u2007',
  '\u2008',
  '\u2009',
  '\u200a',
  '\u200b',
  '\u200c',
  '\u200d',
  '\u2028',
  '\u2029',
  '\u202f',
  '\u205f',
  '\u2060',
  '\u3000',
  '\ufeff',
];

/**
 * This function removes all kinds of spaces in a string no matter if they're at the start, in the middle, or at the end.
 * If you're looking for trimming function, use "stripSpaces" instead.
 * See {@link stripSpaces}
 *
 * @param value string to be parsed
 * @returns parsed string
 */
export function pluckSpaces(value: string): string {
  /**
   * Use "toArray" to split characters in a string. It's emoji and unicode safe.
   * See the reason to use the lib: https://mathiasbynens.be/notes/javascript-unicode
   */
  const chars = toArray(value);
  return chars.filter((char) => !spacesUnicode.includes(char)).join('');
}

/**
 * Strip leading spaces in the provided character array
 *
 * @param chars character array that contains each character in a string
 * @returns character array without leading spaces
 */
function stripLeadingSpaces(chars: Array<string>): Array<string> {
  let hasMetFirstNonSpaceChar = false;

  return chars.map((char) => {
    if (hasMetFirstNonSpaceChar) return char;

    if (!spacesUnicode.includes(char)) {
      hasMetFirstNonSpaceChar = true;
      return char;
    }

    return '';
  });
}

/**
 * Strip trailing spaces in the provided character array
 *
 * @param chars character array that contains each character in a string
 * @returns character array without trailing spaces
 */
function stripTrailingSpaces(chars: Array<string>): Array<string> {
  return stripLeadingSpaces(chars.slice().reverse()).slice().reverse();
}

/**
 * This function removes all kinds of leading and trailing spaces in a string.
 * It's an enhanced method that trims not only simple white spaces but also zero-width white spaces.
 * If you want to remove all spaces in a string, use "pluckSpaces" instead.
 * See {@link pluckSpaces}
 *
 * @param value string that to be trimmed
 * @returns trimmed string
 */
export function stripSpaces(value: string): string {
  return stripTrailingSpaces(stripLeadingSpaces(toArray(value))).reduce(
    (prev, curr) => prev + curr,
    '',
  );
}
