/**
 * Copied from MUI:
 * https://github.com/mui/material-ui/blob/49b3469/packages/mui-utils/src/useEventCallback.ts
 */

import { useCallback, useRef } from 'react';

import type { AnyFunction } from 'utils/types';

import { useEnhancedEffect } from 'hooks/useEnhancedEffect';

/**
 * This function consumes a callback function and returns a static and unchanged
 * function in re-rendering. This's helpful while the callback function is
 * passed into a component. The component won't re-render since the callback
 * function is static.
 *
 * @see {@link https://github.com/facebook/react/issues/14099#issuecomment-440013892}
 *
 * @deprecated Use `useHandler` instead for better DX.
 */
export function useEventCallback<Args extends Array<unknown>, Return>(
  fn: (...args: Args) => Return,
): (...args: Args) => Return {
  const ref = useRef(fn);

  useEnhancedEffect(() => {
    ref.current = fn;
  });

  return useCallback(
    (...args: Args) =>
      // @ts-expect-error hide `this`
      // tslint:disable-next-line:ban-comma-operator
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- Already deprecated
      (0, ref.current!)(...args),
    [],
  );
}

/**
 * The same with `useEventCallback` but more simple to define type.
 * @example
 * ```ts
 * function Test() {
 *   type UnionFn = ((a: 'foo') => void) | ((a: 'bar') => void) | undefined;
 *   const unionFn: UnionFn = undefined;
 *   const unionFnHandler = useHandler<UnionFn>((a) => {
 *     //  ^? ((a: 'foo') => void) | ((a: 'bar') => void)
 *     console.log(a);
 *     //          ^? a: "foo" | "bar"
 *   });
 *   const handlerWithoutGeneric = useHandler((a: 'foo' | 'bar') => {
 *     //  ^? (a: 'foo' | 'bar') => number
 *     console.log(a);
 *     return a.length;
 *   });
 *   const handlerEmpty = useHandler(() => {
 *     //    ^? () => void
 *     console.log('empty');
 *   });
 * }
 * ```
 * @param fn - The handler.
 * @returns The function with the same reference.
 */
// Use this generic if generic input is empty.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function useHandler<Args extends Array<any>, ReturnType, Fn = (...args: Args) => ReturnType>(
  fn: Fn,
): Fn;
// Use this generic if generic input is filled.
function useHandler<Fn extends AnyFunction | undefined>(
  fn: (...params: Parameters<NonNullable<Fn>>) => ReturnType<NonNullable<Fn>>,
  // eslint-disable-next-line @typescript-eslint/ban-types
): Extract<Fn, Function>;
function useHandler<Fn extends AnyFunction | undefined>(
  fn: (...params: Parameters<NonNullable<Fn>>) => ReturnType<NonNullable<Fn>>,
): Fn {
  return useEventCallback<Parameters<NonNullable<Fn>>, ReturnType<NonNullable<Fn>>>(fn) as Fn;
}

export { useHandler };
