export const EDITOR_TAG_REGEX = /(<\$var:.*?>)/g;
export const EDITOR_URL_TAG_REGEX = /<\$var:url\d*>+/g;
export const EDITOR_NAME_TAG_REGEX = /<\$var:name>+/g;
export const EDITOR_PRODUCT_NAME_TAG_REGEX = /<\$var:productName_\d*>+/g;
export const EDITOR_PRODUCT_PRICE_TAG_REGEX = /<\$var:productPrice_\d*>+/g;
export const EDITOR_CUSTOM_TAG_REGEX = /<\$var:custom\d*>+/g;
export const EDITOR_CUSTOM_TEXT_TAG_REGEX = /(<\$var:customText\w*>+)/g;
export const EDITOR_INVITER_NAME_TAG_REGEX = /<\$var:referral_inviter>+/g;
export const EDITOR_INVITEE_NAME_TAG_REGEX = /<\$var:referral_invitee>+/g;
export const EDITOR_INVITATION_LINK_TAG_REGEX = /<\$var:referral_invitation_link>+/g;
export const EDITOR_REFERRAL_ACTIVITY_LINK_REGEX = /<\$var:referral_activity_link>+/g;
export const PRIZE_NAME_KEY = '<$var:prize_name>';
