import { pipe } from 'remeda';

const WHITE_SPACE_HEX = '\u00A0';

const replaceWhitespace = (str: string): string => str.replaceAll(' ', WHITE_SPACE_HEX);

const trimRowsEnd = (rows: Array<string>): Array<string> => rows.map((line) => line.trimEnd());

const splitByNewLine = (str: string): Array<string> => str.split(/\r?\n/);

export const splitToRenderLines = (code: string): Array<string> =>
  pipe(code, replaceWhitespace, splitByNewLine, trimRowsEnd);

// FIXME: Resolve the any type.
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/no-explicit-any
const prettyPrintJSON = (jsonLikeObject: any, space = 2): string =>
  JSON.stringify(jsonLikeObject, null, space);

// FIXME: Resolve the any type.
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types,@typescript-eslint/no-explicit-any
export const toReaderFriendlyFormat = (value: any): Array<string> =>
  splitToRenderLines(prettyPrintJSON(value));
