import { string } from '@chatbotgang/etude/pitch-shifter/string';
import { safePromise } from '@chatbotgang/etude/safe/safePromise';
import { safeString } from '@chatbotgang/etude/string/safeString';
import { getExtension, getType } from 'mime';
import { useTranslation } from 'react-i18next';

import type { MediaInfoResult } from 'lib/mediainfo';
import type { InputProps } from 'shared/components/Input';

import { useTextInput } from 'hooks/useTextInput';
import { parseFileMediainfo } from 'lib/mediainfo';
import { Input } from 'shared/components/Input';
import { useMessage } from 'shared/hooks/ui/useMessage';

const commonImageFileMediainfoFormats = ['JPEG', 'PNG', 'GIF', 'SVG', 'WebP', 'AVIF', 'BMP', 'ICO'];
const commonVideoFileMediainfoFormats = [
  'MPEG-4',
  'Matroska',
  'AVI',
  'QuickTime',
  'Flash Video',
  'Ogg',
  'WebM',
  '3GP',
] as const;
const commonAudioFileMediainfoFormats = [
  'MPEG Audio',
  'AAC',
  'WAV',
  'FLAC',
  'ALAC',
  'WMA',
  'Opus',
  'AIFF',
  'DSD',
] as const;

const fileSubtypeToMediainfoFormatMap: Record<
  string,
  (typeof commonVideoFileMediainfoFormats | typeof commonAudioFileMediainfoFormats)[number]
> = {
  mp4: 'MPEG-4',
  'x-matroska': 'Matroska',
  'x-msvideo': 'AVI',
  quicktime: 'QuickTime',
  'x-flv': 'Flash Video',
  ogg: 'Ogg',
  webm: 'WebM',
  '3gpp': '3GP',
  mpeg: 'MPEG Audio',
  aac: 'AAC',
  wav: 'WAV',
  flac: 'FLAC',
  alac: 'ALAC',
  wma: 'WMA',
  opus: 'Opus',
  aiff: 'AIFF',
  dsd: 'DSD',
};

export type FileInputProps = Omit<InputProps, 'onChange'> & {
  onChange?: (e: React.ChangeEvent<HTMLInputElement>, parsedMediainfo?: MediaInfoResult) => void;
};

/**
 * Custom FileInput component that validates the file type and subtype.
 * It also clear the selected file after the onChange event which allow the user to select the same file again.
 *
 * @example
 * ```tsx
 * <FileInput
    accept="image/jpeg,image/png" // any valid MIME type or file extension, wildcard like (image/*) is also supported
    onChange={(e) => {
      // run your business logic here, the file type validation is run before this callback is called
    }}
  />
 * ```
 */
export const FileInput = ({ accept, onChange, ...rest }: FileInputProps) => {
  const fileInput = useTextInput('');
  const { message } = useMessage();
  const { t } = useTranslation();

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    // Input files will be lost after the async code below so we need to clone then and keep them before the async code
    const clonedFileList = e.target.files
      ? Object.assign<{ length: number; item: (index: number) => File | null }, FileList | null>(
          {
            length: e.target.files.length,
            item(index: number) {
              return (this as FileList)?.[index] ?? null;
            },
          },
          e.target.files,
        )
      : null;

    // Since we have async code that the event target might be lost after the async code execution, we need to clone the event to persist it
    const persistedEvent = Object.assign({}, e, {
      target: {
        ...e.target,
        files: clonedFileList,
      },
      currentTarget: {
        ...e.currentTarget,
        files: clonedFileList,
      },
    });

    const file = clonedFileList?.[0];

    if (!file) {
      message.error(t('common.failToFetchModule', { module: t('glossary.file') }));
      return;
    }

    /**
     * Parse the accept attribute to get the file types (image or video) and file subtypes (jpeg or mp4) that are accepted.
     * It supports MIME types, file extensions, and wildcard like (image/*)
     */
    const acceptFileFormats = accept
      ?.split(',')
      .map((mimeType) => {
        const safeMimeType = safeString(mimeType);

        if (safeMimeType.search(/\/\*/) > -1) {
          return safeMimeType.split('/')[0];
        }

        const type = getType(safeMimeType);
        const extension = getExtension(safeMimeType);
        return type ? getExtension(type) : extension;
      })
      .flatMap((fileType) => {
        if (!fileType) return [];
        if (fileType in fileSubtypeToMediainfoFormatMap) {
          return [fileSubtypeToMediainfoFormatMap[fileType]];
        }
        return [fileType];
      });

    let parsedResult: MediaInfoResult | undefined = undefined;

    if (acceptFileFormats) {
      const safeParsedResult = await safePromise(() => parseFileMediainfo(file));
      parsedResult = safeParsedResult.data ?? undefined;
      const tracks = safeParsedResult.data ? (safeParsedResult.data.media?.track ?? []) : [];
      const parsedFormats = tracks
        .map((track) => track.Format)
        .filter((format) => format !== undefined)
        .filter((format) => format !== '');
      const parsedTypes = tracks
        .map((track) => track['@type'])
        .filter((type) => type !== undefined);
      const parsedFileFormat = string()(
        tracks.find((track) => track['@type'] === 'General')?.Format ?? '',
      );

      /**
       * Sometimes MediaInfo can't detect the file format correctly.
       * We ensure that the parsed file format is not an outlier.
       * If the parsed file format is an outlier, we don't show the error message.
       */
      const commonlist = (() => {
        const imageCommon =
          acceptFileFormats.indexOf('image') > -1 ||
          acceptFileFormats.some((format) =>
            commonImageFileMediainfoFormats.some((commonFormat) =>
              new RegExp(format, 'i').test(commonFormat),
            ),
          );
        const videoCommon =
          acceptFileFormats.indexOf('video') > -1 ||
          acceptFileFormats.some((format) =>
            commonVideoFileMediainfoFormats.some((commonFormat) =>
              new RegExp(format, 'i').test(commonFormat),
            ),
          );
        const audioCommon =
          acceptFileFormats.indexOf('audio') > -1 ||
          acceptFileFormats.some((format) =>
            commonAudioFileMediainfoFormats.some((commonFormat) =>
              new RegExp(format, 'i').test(commonFormat),
            ),
          );

        return [
          ...(imageCommon ? commonImageFileMediainfoFormats : []),
          ...(videoCommon ? commonVideoFileMediainfoFormats : []),
          ...(audioCommon ? commonAudioFileMediainfoFormats : []),
        ];
      })();

      const withParsedFileFormatOutlier =
        parsedFormats.length > 0 && !parsedFormats.every((format) => commonlist.includes(format));

      /**
       * File format validation
       * It validates the file subtypes like png, jpeg, mp4, etc.
       * Moreover, it also validates the file type like image, video, etc.
       */
      const isFileFormatInvalid =
        !parsedFormats.some((parsedFormat) => {
          return acceptFileFormats.some((format) => {
            return new RegExp(parsedFormat, 'i').test(format);
          });
        }) &&
        !parsedTypes.some((type) => {
          return acceptFileFormats.some((format) => {
            return new RegExp(format, 'i').test(type);
          });
        });

      if (
        !withParsedFileFormatOutlier &&
        parsedResult &&
        parsedFormats.length > 0 &&
        parsedFileFormat &&
        isFileFormatInvalid
      ) {
        message.error(
          t('validation.fileTypes.notAcceptedWithDetected', {
            accepted: acceptFileFormats.join(', ').toLocaleUpperCase(),
            detected: parsedFileFormat,
          }),
        );
        return;
      }
    }

    onChange?.(persistedEvent, parsedResult);
    fileInput.clear();
  };

  return (
    <Input {...rest} type="file" accept={accept} onChange={handleChange} value={fileInput.value} />
  );
};
