/* eslint-disable no-restricted-imports */
import { useHandler } from '@chatbotgang/etude/react/useHandler';
import { safeString } from '@chatbotgang/etude/string/safeString';
import styled from '@emotion/styled';
import { InputNumber } from 'antd';
import AntInput from 'antd/es/input';
import { merge } from 'es-toolkit/compat';
import { forwardRef, memo, useCallback } from 'react';
import { pipe } from 'remeda';

import type { SafeStringOptions } from '@chatbotgang/etude/string/safeString';
import type {
  InputProps as AntInputProps,
  InputRef,
  TextAreaProps as AntTextAreaProps,
} from 'antd/es/input';
import type { TextAreaRef } from 'antd/es/input/TextArea';
import type { ComponentProps, ComponentRef, KeyboardEventHandler } from 'react';

import { theme } from 'theme';

interface CustomInputProps {
  safeString?: Partial<SafeStringOptions> | false;
  preSafeString?: (value: string) => string;
  postSafeString?: (value: string) => string;
}

const doNothing = (value: string) => value;

const defaultSafeStringOptions: SafeStringOptions = {
  allowMultipleSpaces: true,
  deburr: false,
  trim: true,
};

const StyledTextArea = styled(AntInput.TextArea)`
  @layer styled-component {
    & {
      color: ${theme.colors.neutral010};
      &:disabled {
        color: ${theme.colors.neutral007v2};
      }
    }
  }
`;

export interface TextAreaProps extends AntTextAreaProps, CustomInputProps {}

/**
 * Use this styled component instead of Ant Design TextArea.
 *
 * It will automatically format the input value to be safe when blurred.
 *
 * You can configure the safe string options by passing a partial of
 * `SafeStringOptions` to the 'safeString' prop.
 *
 * @see {@link SafeStringOptions}
 */
export const TextArea = memo(
  forwardRef<TextAreaRef, TextAreaProps>(
    (
      {
        safeString: safeStringOptions,
        preSafeString = doNothing,
        postSafeString = doNothing,
        ...props
      },
      ref,
    ) => {
      const onBlur = useHandler<ComponentProps<typeof AntInput.TextArea>['onBlur']>((e) => {
        props.onBlur?.(e);
        if (e.isDefaultPrevented()) return;

        if (!props.onChange) return;

        if (typeof props.value !== 'string') return;

        if (safeStringOptions === false) return;

        // Start safe string
        const mergedSafeStringOptions: SafeStringOptions = merge(
          {},
          { ...defaultSafeStringOptions, trim: false },
          safeStringOptions,
        );
        const value = props.value;

        const formattedString = pipe(
          value,
          preSafeString,
          (input) => safeString(input, mergedSafeStringOptions),
          postSafeString,
        );

        if (formattedString === value) return;

        props.onChange?.(Object.assign({}, e, { target: { ...e.target, value: formattedString } }));
      });

      return <StyledTextArea ref={ref} {...props} onBlur={onBlur} />;
    },
  ),
);

interface NumberInputProps extends ComponentProps<typeof InputNumber> {
  /** Allow decimal number input or not */
  allowDecimal?: boolean;
  /** Allow negative number input or not */
  allowNegative?: boolean;
}

export const NumberInput = forwardRef<
  ComponentRef<typeof InputNumber>,
  Omit<NumberInputProps, 'type'>
>(function NumberInput(
  { allowDecimal = false, allowNegative = false, onKeyDown, onChange, ...props },
  ref,
) {
  const handleKeyDown = useCallback<KeyboardEventHandler<HTMLInputElement>>(
    (e) => {
      if (!allowDecimal && e.key === '.') {
        e.preventDefault();
      }
      if (!allowNegative && e.key === '-') {
        e.preventDefault();
      }
      onKeyDown?.(e);
    },
    [allowDecimal, allowNegative, onKeyDown],
  );

  return (
    <InputNumber
      type="number"
      max={999}
      onChange={(value) => {
        const newValue = value ?? props.min ?? value;
        onChange?.(newValue);
      }}
      {...props}
      ref={ref}
      onKeyDown={handleKeyDown}
    />
  );
});

export interface InputProps extends AntInputProps, CustomInputProps {}

/**
 * Use this styled component instead of Ant Design Input.
 *
 * It will automatically format the input value to be safe when blurred.
 *
 * You can configure the safe string options by passing a partial of
 * `SafeStringOptions` to the 'safeString' prop.
 *
 * @see {@link SafeStringOptions}
 */
export const Input = Object.assign(
  forwardRef<InputRef, InputProps>(
    (
      {
        safeString: safeStringOptions,
        preSafeString = doNothing,
        postSafeString = doNothing,
        ...props
      },
      ref,
    ) => {
      const onBlur = useHandler<ComponentProps<typeof AntInput>['onBlur']>((e) => {
        props.onBlur?.(e);
        if (e.isDefaultPrevented()) return;

        if (!props.onChange) return;

        if (typeof props.value !== 'string') return;

        if (safeStringOptions === false) return;

        // Don't format password input by default
        if (props.type === 'password') return;

        // Start safe string
        const mergedSafeStringOptions: SafeStringOptions = merge(
          {},
          defaultSafeStringOptions,
          safeStringOptions,
        );
        const value = props.value;

        const formattedString = pipe(
          value,
          preSafeString,
          (input) => safeString(input, mergedSafeStringOptions),
          postSafeString,
        );

        if (formattedString === value) return;

        props.onChange?.(Object.assign({}, e, { target: { ...e.target, value: formattedString } }));
      });

      return <AntInput ref={ref} {...props} onBlur={onBlur} />;
    },
  ),
  {
    TextArea,
    Group: AntInput.Group,
    Search: AntInput.Search,
    Password: AntInput.Password,
    NumberInput: NumberInput,
  },
);
