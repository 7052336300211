import { getApps, initializeApp } from 'firebase/app';

import {
  FIREBASE_CONTENT_STORAGE_API_KEY,
  FIREBASE_CONTENT_STORAGE_APP_ID,
  FIREBASE_CONTENT_STORAGE_AUTH_DOMAIN,
  FIREBASE_CONTENT_STORAGE_DATABASE_URL,
  FIREBASE_CONTENT_STORAGE_MESSAGING_SENDER_ID,
  FIREBASE_CONTENT_STORAGE_NAME,
  FIREBASE_CONTENT_STORAGE_PROJECT_ID,
  FIREBASE_CONTENT_STORAGE_STORAGE_BUCKET,
  FIREBASE_DATA_STORAGE_API_KEY,
  FIREBASE_DATA_STORAGE_APP_ID,
  FIREBASE_DATA_STORAGE_AUTH_DOMAIN,
  FIREBASE_DATA_STORAGE_DATABASE_URL,
  FIREBASE_DATA_STORAGE_MESSAGING_SENDER_ID,
  FIREBASE_DATA_STORAGE_NAME,
  FIREBASE_DATA_STORAGE_PROJECT_ID,
  FIREBASE_DATA_STORAGE_STORAGE_BUCKET,
  FIREBASE_LOCALE_STORAGE_API_KEY,
  FIREBASE_LOCALE_STORAGE_APP_ID,
  FIREBASE_LOCALE_STORAGE_AUTH_DOMAIN,
  FIREBASE_LOCALE_STORAGE_MESSAGING_SENDER_ID,
  FIREBASE_LOCALE_STORAGE_NAME,
  FIREBASE_LOCALE_STORAGE_PROJECT_ID,
  FIREBASE_LOCALE_STORAGE_STORAGE_BUCKET,
  FIREBASE_TEMP_STORAGE_API_KEY,
  FIREBASE_TEMP_STORAGE_APP_ID,
  FIREBASE_TEMP_STORAGE_AUTH_DOMAIN,
  FIREBASE_TEMP_STORAGE_DATABASE_URL,
  FIREBASE_TEMP_STORAGE_MESSAGING_SENDER_ID,
  FIREBASE_TEMP_STORAGE_NAME,
  FIREBASE_TEMP_STORAGE_PROJECT_ID,
  FIREBASE_TEMP_STORAGE_STORAGE_BUCKET,
} from 'env';

type FirebaseConfig = {
  name: string | undefined;
  options: {
    apiKey: string;
    authDomain: string;
    databaseURL?: string;
    projectId: string;
    storageBucket: string;
    messagingSenderId: string;
    appId: string;
  };
};

const configs: Array<FirebaseConfig> = [
  {
    // content storage
    name: FIREBASE_CONTENT_STORAGE_NAME,
    options: {
      apiKey: FIREBASE_CONTENT_STORAGE_API_KEY,
      authDomain: FIREBASE_CONTENT_STORAGE_AUTH_DOMAIN,
      databaseURL: FIREBASE_CONTENT_STORAGE_DATABASE_URL,
      projectId: FIREBASE_CONTENT_STORAGE_PROJECT_ID,
      storageBucket: FIREBASE_CONTENT_STORAGE_STORAGE_BUCKET,
      messagingSenderId: FIREBASE_CONTENT_STORAGE_MESSAGING_SENDER_ID,
      appId: FIREBASE_CONTENT_STORAGE_APP_ID,
    },
  },
  {
    // data storage
    name: FIREBASE_DATA_STORAGE_NAME,
    options: {
      apiKey: FIREBASE_DATA_STORAGE_API_KEY,
      authDomain: FIREBASE_DATA_STORAGE_AUTH_DOMAIN,
      databaseURL: FIREBASE_DATA_STORAGE_DATABASE_URL,
      projectId: FIREBASE_DATA_STORAGE_PROJECT_ID,
      storageBucket: FIREBASE_DATA_STORAGE_STORAGE_BUCKET,
      messagingSenderId: FIREBASE_DATA_STORAGE_MESSAGING_SENDER_ID,
      appId: FIREBASE_DATA_STORAGE_APP_ID,
    },
  },
  {
    // locale storage
    name: FIREBASE_LOCALE_STORAGE_NAME,
    options: {
      apiKey: FIREBASE_LOCALE_STORAGE_API_KEY,
      authDomain: FIREBASE_LOCALE_STORAGE_AUTH_DOMAIN,
      projectId: FIREBASE_LOCALE_STORAGE_PROJECT_ID,
      storageBucket: FIREBASE_LOCALE_STORAGE_STORAGE_BUCKET,
      messagingSenderId: FIREBASE_LOCALE_STORAGE_MESSAGING_SENDER_ID,
      appId: FIREBASE_LOCALE_STORAGE_APP_ID,
    },
  },
  {
    // temp storage
    name: FIREBASE_TEMP_STORAGE_NAME,
    options: {
      apiKey: FIREBASE_TEMP_STORAGE_API_KEY,
      authDomain: FIREBASE_TEMP_STORAGE_AUTH_DOMAIN,
      databaseURL: FIREBASE_TEMP_STORAGE_DATABASE_URL,
      projectId: FIREBASE_TEMP_STORAGE_PROJECT_ID,
      storageBucket: FIREBASE_TEMP_STORAGE_STORAGE_BUCKET,
      messagingSenderId: FIREBASE_TEMP_STORAGE_MESSAGING_SENDER_ID,
      appId: FIREBASE_TEMP_STORAGE_APP_ID,
    },
  },
];

export function initialFirebase(): void {
  if (getApps().length < 1) {
    configs.forEach((config) => {
      initializeApp(config.options, config.name || undefined);
    });
  }
}
