import { motion } from 'framer-motion';

import type { BoxProps } from 'components/layoutUtils';
import type { CSSProperties, ReactNode } from 'react';

import { cardVariants } from 'components/Card/variants';
import { superBoxStyle } from 'components/layoutUtils';
import { styled } from 'shared/utils/styled';
import { shouldNotForwardSystemProps } from 'shared/utils/styled/styledSystem';
import { theme } from 'theme';

interface CardWrapperProps extends BoxProps {
  isParallaxEffect?: boolean;
  isBlock?: boolean;
  style?: CSSProperties;
  onAnimationComplete?(): void;
  children: ReactNode;
}

export const AniCardsWrapper = styled(motion.div)`
  opacity: 0;
`;

const Card = styled(motion.div).withConfig({
  shouldForwardProp: shouldNotForwardSystemProps('isBlock'),
})<CardWrapperProps>`
  ${superBoxStyle};
  position: relative;
  box-sizing: border-box;
  box-shadow: 0 3px 5px 0 rgba(18, 31, 48, 0.05);
  border-radius: 4px;
  vertical-align: top;
  overflow: hidden;
  border: 1px solid ${theme.colors.neutral005};
  /* pointer-events: ${({ isBlock }) => (isBlock ? 'none' : 'auto')}; */
  > div:not(.hint-box) {
    filter: ${({ isBlock }) => (isBlock ? 'blur(4px)' : '')};
  }
  ${({ isBlock }) =>
    isBlock
      ? `
  &:before{
    border-radius: 3px;
    content:'';
    width:100%;
    height:100%;
    position:absolute;
    top:0;
    left:0;
    background-color:white;
    z-index:10;
    opacity:0.8;
    pointer-events:auto;
  }
  `
      : null}
`;

export const CardWrapper = ({
  children,
  display = 'inline-block',
  ...restProps
}: CardWrapperProps) => {
  return (
    <Card
      {...restProps}
      display={display}
      variants={cardVariants}
      onAnimationComplete={restProps.onAnimationComplete}
    >
      {children}
    </Card>
  );
};
