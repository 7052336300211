import {
  PARAM_RICH_TEXT_FIELD_NAME_INDEX,
  RICH_EDITOR_ID_NOT_FOUND,
} from 'components/LineMessageEditor/constants';

import type {
  CardDataType,
  CardRichEditorIds,
} from 'components/LineMessageEditor/models/templateDataAndTypes/card';
import type { EditorDataType } from 'components/LineMessageEditor/models/templateDataAndTypes/types';

import {
  isCardDataRule,
  isTextDataRule,
} from 'components/LineMessageEditor/models/templateDataAndTypes/types';

const { CardTitle: cardTitleIndex, CardDescription: cardDescriptionIndex } =
  PARAM_RICH_TEXT_FIELD_NAME_INDEX;

/**
 * Get a generated `richEditorId` for `DataType.format.richEditorId`
 *
 * DISCLAIMER: Using unix timestamp with `Math.random()` is only a temporary solution of
 *             pseudo randomness.
 *             In short term, it'll suffice current product user stories, but it won't
 *             be an appropriate way of generating ID-like values.
 *             This function shouldn't be maintained further for complex scenarios.
 *
 *             **Plus, `richEditorId` SHOULD NOT be a number value. It should be either
 *             a UUID, NanoID or other randomized `string` values.**
 *
 * @param index - In case `Date.now()` generates same values due to call stacks
 *                or race conditions, add an index value for better uniqueness.
 *                `index` is 0 by default.
 */
export function generateRichEditorId(index = 0): number {
  return Math.floor(((Date.now() + index) * Math.random()) / 1000);
}

/**
 * Get the ID tuple of {@link CardRichEditorIds}
 */
export function createRichEditorIdTuple(format?: CardDataType['format']): CardRichEditorIds {
  if (format) {
    return [format[cardTitleIndex].richEditorId, format[cardDescriptionIndex].richEditorId];
  }
  return [generateRichEditorId(cardTitleIndex), generateRichEditorId(cardDescriptionIndex)];
}

/**
 * Get the first appeared `richEditorId` in {@link EditorDataType[] initialData} across modules
 *
 * @param initialData
 * @returns the first `richEditorId` or {@link RICH_EDITOR_ID_NOT_FOUND -1} if it could not find one
 */
export function findFirstRichEditorId(initialData: Array<EditorDataType> | undefined): number {
  let result = RICH_EDITOR_ID_NOT_FOUND;
  if (!initialData) {
    return result;
  }
  initialData.forEach((message) => {
    // Skip the rest of the looping for already found the first `richEditorId`
    if (result !== RICH_EDITOR_ID_NOT_FOUND) {
      return;
    }
    if (isTextDataRule(message)) {
      result = message.format.richEditorId;
    } else if (isCardDataRule(message) && Boolean(message.data?.format?.[0]?.richEditorId)) {
      result = message.data.format[0].richEditorId;
    }
  });
  return result;
}
