import { immerable } from 'immer';

import { PARAM_PLACEHOLDER } from 'components/LineMessageEditor/constants';

import type {
  AbstractEditorData,
  CustomParameter,
  CustomParameterData,
  ImageSourceType,
} from './types';
import type { LineFieldType } from 'components/LineMessageEditor/models/templateDataAndTypes/LineFieldType';

import { createCustomParameter } from 'components/LineMessageEditor/models/templateDataAndTypes/types';
import { generateMappingKey } from 'components/LineMessageEditor/utils/transformKey';

class ImageDataData {
  [immerable] = true;
  content_url = '';
  key = '';
  actions = [];
  parameters = [];

  constructor(props: Partial<ImageDataData> = {}) {
    Object.assign(this, props);
  }

  setUrl(url = '') {
    this.content_url = url;
  }

  setMappingKey(mappingKey = '') {
    this.key = mappingKey;
  }
}

/**
 * The state of Image
 * @see https://immerjs.github.io/immer/complex-objects
 */
export class ImageData
  implements AbstractEditorData<ImageDataData, unknown, unknown, CustomParameterData>
{
  // Since immer need immutable to perform mutable action
  // https://github.com/immerjs/immer/tree/v3.2.1#supported-object-types
  [immerable] = true;
  module_id: LineFieldType.Image = 2;
  data = new ImageDataData();
  actions = [];
  parameters: Array<CustomParameter> = [];
  quick_reply = { items: [] };

  constructor(props: Partial<ImageData> = {}) {
    const { data, ...rest } = props;
    Object.assign(this, rest);
    this.data = new ImageDataData(data);
  }

  setUrl(url = ''): ImageData {
    this.data.setUrl(url);
    return this;
  }

  setUrlWithKey(isParam: boolean, url = ''): ImageData {
    if (isParam) {
      this.imageSourceToParam(url);
    } else {
      this.imageSourceToLocal(url);
    }
    return this;
  }

  switchImageSourceType(currentType: ImageSourceType, previousType: ImageSourceType): void {
    if (currentType === previousType) {
      return;
    }
    if (currentType === 'local') {
      this.imageSourceToLocal();
    }
    if (currentType === 'param') {
      this.imageSourceToParam('');
    }
  }

  private imageSourceToLocal(url = ''): void {
    this.parameters = this.parameters.filter((p) => p.mappingKey !== this.data.key);
    this.data.setUrl(url);
    this.data.setMappingKey('');
  }

  private imageSourceToParam(key: string): void {
    this.data.setUrl(PARAM_PLACEHOLDER.image);
    const customImageParameterIndex = this.parameters.findIndex(
      (p) => p.mappingKey === this.data.key,
    );
    if (customImageParameterIndex !== -1) {
      // Update the existing param image
      this.parameters[customImageParameterIndex].key = key;
    } else {
      // Create a new param image
      const mappingKey = generateMappingKey('customImage');
      this.data.setMappingKey(mappingKey);
      this.parameters.push(createCustomParameter(key, mappingKey));
    }
  }
}

export const imageData = new ImageData();
